import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Typography, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MonthPicker } from 'components/ui/MonthPicker';
import { Formik } from 'formik';
import clsx from 'clsx';
import {
  getExcoInfoModalDataRequest,
  resetExcoInfoModalData,
  sendExcoInfoRequest,
  setModalVisibility,
  getCheckPendingChanges,
} from 'modules/SalaryModule/SalaryModal/actions';
import {
  selectExcoInfoModalData,
  selectExcoInfoModalDataLastFinalizedDate,
  selectExcoInfoModalDataStatus,
  selectModalVisibility,
  selectIsPendingChanges,
} from 'modules/SalaryModule/SalaryModal/selectors';
import { useSimpleMedia } from 'hooks/useMedia';
import {
  DATE_FORMAT_DOTTED,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import { Spinner } from 'components/Spinner';
import { selectUserCurrentCompany } from 'modules/loggedUserInfo/selectors';
import { getOrderNumberForSendInfoStatistics } from 'helpers/sendInfoModal';
import { openSuccessNotification } from 'helpers/notifications';
import {
  EMPLOYEE_MODAL_INFO,
  EXCO_INFO_MODAL_DATA_LABELS,
} from 'const/translations/SalaryMainPage';
import { Button } from 'components/ui';
import Styles from './InfoModal.module.css';

const SCREEN_WIDTH_BREAKPOINT_VALUE = 360;
const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const InfoModal = memo(() => {
  const isVisible = useSelector(selectModalVisibility);
  const modalData = useSelector(selectExcoInfoModalData);
  const isPendingChanges = useSelector(selectIsPendingChanges);
  const modalDataStatus = useSelector(selectExcoInfoModalDataStatus);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(setModalVisibility(false));
  }, [dispatch]);
  const lastFinalizedDate = useSelector(
    selectExcoInfoModalDataLastFinalizedDate
  );

  const [activeMonth, setActiveMonth] = useState(moment().startOf('month'));

  const { id: companyId } = useSelector(selectUserCurrentCompany);

  useEffect(() => {
    if (isVisible) {
      dispatch(getExcoInfoModalDataRequest({ companyId }));
      return;
    }
    dispatch(resetExcoInfoModalData());
  }, [dispatch, isVisible, companyId]);

  useEffect(() => {
    if (isVisible) {
      const month = activeMonth.month() + 1;
      const year = activeMonth.year();

      dispatch(getCheckPendingChanges({ companyId, month, year }));
      return;
    }
    dispatch(resetExcoInfoModalData());
  }, [dispatch, isVisible, companyId, activeMonth]);

  const openNotification = useCallback(() => {
    openSuccessNotification({
      message: EMPLOYEE_MODAL_INFO.SUCCESS_MESSAGE,
      color: 'darkGreen',
      wide: true,
    });
  }, []);

  const onSubmit = useCallback(
    ({ comment }) => {
      dispatch(
        sendExcoInfoRequest({
          onSuccess: openNotification,
          date: activeMonth,
          comment,
          companyId,
        })
      );
      onClose();
    },
    [dispatch, openNotification, activeMonth, companyId, onClose]
  );

  const isThereNothingToFinalize = useMemo(
    () => Object.values(modalData).every((value) => !value),
    [modalData]
  );

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      className={Styles.wrapper}
      width={isMobileVersion ? '100%' : '422px'}
      destroyOnClose
    >
      <Typography.Title level={4} className={Styles.textTitle}>
        {EMPLOYEE_MODAL_INFO.TITLE}
      </Typography.Title>
      {lastFinalizedDate && (
        <Typography.Paragraph className={Styles.textSubtitle}>
          {`${EMPLOYEE_MODAL_INFO.SUBTITLE}  ${moment(lastFinalizedDate).format(
            DATE_FORMAT_DOTTED
          )}`}
        </Typography.Paragraph>
      )}
      <div className={Styles.blockWrapper}>
        <Typography.Text className={Styles.textLabel}>
          {EMPLOYEE_MODAL_INFO.LABELS.MONTH_PICKER}
        </Typography.Text>
        <MonthPicker value={activeMonth} setValue={setActiveMonth} />
      </div>
      <div className={Styles.blockWrapper}>
        <Typography.Text className={Styles.textLabel}>
          {EMPLOYEE_MODAL_INFO.LABELS.STATISTICS}
        </Typography.Text>
        <div className={Styles.statisticsWrapper}>
          {[STATUS_NOT_REQUESTED, STATUS_LOADING].includes(modalDataStatus) ? (
            <Spinner className={Styles.spinnerWrapper} />
          ) : (
            Object.entries(modalData).map(([label, value]) => (
              <div
                key={label}
                style={{ order: getOrderNumberForSendInfoStatistics(label) }}
                className={Styles.textStatistics}
              >
                <span className={Styles.statisticsNumber}>{value}</span>
                <span className={Styles.statisticsText}>
                  {EXCO_INFO_MODAL_DATA_LABELS[label]}
                </span>
              </div>
            ))
          )}
        </div>
      </div>
      <Formik
        initialValues={{ comment: '' }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (values.comment.length > 200) {
            errors.comment = '200 max symbols';
          }
          return errors;
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <textarea
              name="comment"
              className={clsx(Styles.modalInput, {
                [Styles.errorModalInput]: errors.comment,
              })}
              value={values.comment}
              onChange={handleChange}
              placeholder={EMPLOYEE_MODAL_INFO.TEXT_AREA_PLACEHOLDER}
            />
            <div className={Styles.buttonsWrapper}>
              <Button
                variant="secondary"
                onClick={onClose}
                className={Styles.button}
              >
                {EMPLOYEE_MODAL_INFO.CANCEL_BUTTON}
              </Button>

              {isPendingChanges ? (
                <Tooltip
                  placement="topLeft"
                  arrowPointAtCenter
                  title="Opération impossible, vous avez déjà validé les EVP pour cette période"
                  overlayClassName={Styles.toolTip}
                  trigger="hover"
                >
                  <div>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        modalDataStatus !== STATUS_SUCCESS ||
                        isThereNothingToFinalize ||
                        isPendingChanges
                      }
                      className={Styles.button}
                    >
                      {EMPLOYEE_MODAL_INFO.SUBMIT_BUTTON}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    modalDataStatus !== STATUS_SUCCESS ||
                    isThereNothingToFinalize ||
                    isPendingChanges
                  }
                  className={Styles.button}
                >
                  {EMPLOYEE_MODAL_INFO.SUBMIT_BUTTON}
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
});
