import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  deleteLeaveSuccess,
  editLeaveSuccess,
  createVacationSuccess,
  createVacationError,
} from 'modules/SalaryModule/Calendar/actions';
import { downloadFile } from 'helpers/common';
import { EMPTY_FUNCTION } from 'const';
import {
  CREATE_VACATION_REQUEST,
  DELETE_LEAVE_REQUEST,
  DOWNLOAD_INITIAL_ABSENCE_SUPPORTING_DOC,
  DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL,
  EDIT_LEAVE_REQUEST,
  EMPLOYEE_ABSENCES_REQUEST,
  employeeAbsencesRequest,
  employeeAbsencesSuccess,
  employeeAbsencesError,
  downloadInitialAbsenceSupportingDoc,
  setTableDefaultPage,
} from '../actions';
import {
  createVacationRequest,
  createVacationListRequest,
  deleteLeaveService,
  deleteVacationSupportDocRequest,
  editLeaveService,
  fetchLeaveSupportingDoc,
  getEmployeeAbsences,
  uploadVacationSupportDocRequest,
} from '../services';
import { selectVacationModalLeaveObject } from '../selectors';

function* createVacationWorker({ payload }) {
  try {
    const {
      companyId,
      file,
      data: vacationData,
      employeeId,
      onSuccess,
      employeeName,
      employeePageInTable,
    } = payload;

    const withAddedUsers = Array.isArray(employeeId);

    let absence;

    if (file) {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('file', file.originFileObj);

      const { data } = yield call(uploadVacationSupportDocRequest, {
        companyId,
        formData,
      });

      if (data?.id) {
        vacationData.supportingDoc = data.id;
      }
    }

    if (withAddedUsers) {
      absence = yield call(createVacationListRequest, {
        companyId,
        data: { ...vacationData, employeesId: employeeId },
      });
    } else {
      absence = yield call(createVacationRequest, {
        companyId,
        data: vacationData,
        employeeId,
      });
    }

    yield put(createVacationSuccess({ leaveObj: absence }));

    yield put(
      setTableDefaultPage(
        employeePageInTable === 'number' ? employeePageInTable : 1
      )
    );
    onSuccess(vacationData.type, employeeName);
  } catch (error) {
    console.warn(error);

    yield put(createVacationError());

    if (error.error === `Le code absence n'existe pas`) {
      notification.error({ message: 'Code absence inexistant' });
      return;
    }

    notification.error({ message: error.error });
  }
}

function* editLeaveWorker({
  payload: {
    companyId,
    file,
    leaveObj,
    employeeName,
    data: leaveData = {},
    onSuccess = EMPTY_FUNCTION,
  },
}) {
  try {
    // delete file
    const shouldDelete =
      leaveObj?.supportingDoc?.id &&
      (!file || file?.uid !== leaveObj.supportingDoc.id);
    if (shouldDelete) {
      yield call(deleteVacationSupportDocRequest, {
        companyId,
        fileId: leaveObj.supportingDoc.id,
      });
    }

    // change file
    if (file && file.uid !== leaveObj.supportingDoc.id) {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('file', file.originFileObj);

      const { data } = yield call(uploadVacationSupportDocRequest, {
        companyId,
        formData,
      });

      if (data?.id) {
        // eslint-disable-next-line no-param-reassign
        leaveData.supportingDoc = data.id;
      }
    }

    const { type: _, ...relevantEditData } = leaveData;
    const { data } = yield call(editLeaveService, {
      leaveId: leaveObj.id,
      leaveData: relevantEditData,
    });

    const updatedAbsence =
      file && file.uid !== leaveObj.supportingDoc.id
        ? {
            ...data.absence,
            supportingDoc: {
              name: file.name,
              id: data.absence.supportingDoc.id,
            },
          }
        : data.absence;

    yield put(editLeaveSuccess({ leaveObj: updatedAbsence }));
    onSuccess(leaveObj.type, employeeName);
  } catch (error) {
    console.warn(error);
  }
}

function* deleteLeaveWorker({ payload: { id } }) {
  try {
    const { data } = yield call(deleteLeaveService, { absenceId: id });
    yield put(deleteLeaveSuccess({ leaveObj: data.absence }));
  } catch (error) {
    console.warn(error);
  }
}

function* downloadInitialAbsenceSupportingDocWorker({
  payload: { fileId, companyId },
}) {
  try {
    const { fileData, fileName } = yield call(fetchLeaveSupportingDoc, {
      fileId,
      companyId,
    });
    yield call(downloadFile, fileData, fileName);
  } catch (error) {
    console.warn(error);
  }
}

function* downloadAbsenceAttachmentWithoutModalWorker({
  payload: { companyId, employeeId },
}) {
  try {
    yield put(employeeAbsencesRequest({ companyId, employeeId }));

    const {
      supportingDoc: { id },
    } = yield select(selectVacationModalLeaveObject);

    yield put(
      downloadInitialAbsenceSupportingDoc({
        fileId: id,
        companyId,
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

function* fetchEmployeeAbsencesWorker({ payload }) {
  try {
    const { data } = yield call(getEmployeeAbsences, payload);
    yield put(employeeAbsencesSuccess(data));
  } catch (error) {
    yield put(employeeAbsencesError(error));
    console.warn(error);
  }
}

export function* vacationMainSaga() {
  yield takeLatest(CREATE_VACATION_REQUEST, createVacationWorker);
  yield takeLatest(EDIT_LEAVE_REQUEST, editLeaveWorker);
  yield takeLatest(DELETE_LEAVE_REQUEST, deleteLeaveWorker);
  yield takeLatest(
    DOWNLOAD_INITIAL_ABSENCE_SUPPORTING_DOC,
    downloadInitialAbsenceSupportingDocWorker
  );
  yield takeLatest(
    DOWNLOAD_ABSENCE_ATTACHMENT_WITHOUT_MODAL,
    downloadAbsenceAttachmentWithoutModalWorker
  );
  yield takeLatest(EMPLOYEE_ABSENCES_REQUEST, fetchEmployeeAbsencesWorker);
}
