import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as TagCross } from 'assets/icons/tag-cross.svg';

import Styles from './CategoryTag.module.css';

const CATEGORY_TAG_MAX_LENGTH = 51;

export const CategoryTag = ({ id, label, toggleTag, isActiveTag }) => {
  const tagOnClick = useCallback(() => toggleTag(id), [id, toggleTag]);

  return (
    <div
      key={id}
      className={clsx(Styles.category, {
        [Styles.categoryActive]: isActiveTag,
      })}
      onClick={tagOnClick}
      {...(label.length > CATEGORY_TAG_MAX_LENGTH ? { title: label } : {})}
    >
      <span className={Styles.newsCategoryLabel}>{label}</span>
      {isActiveTag && <TagCross />}
    </div>
  );
};

CategoryTag.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  toggleTag: PropTypes.func,
  isActiveTag: PropTypes.bool,
};
