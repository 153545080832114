import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserAuthorizedModules,
  selectUserCurrentCompanyActivatedStatus,
  selectUserCurrentCompanyDosId,
  selectUserCurrentCompanyExerciceWithYear,
  selectUserPackagesNames,
  selectIsMainUser,
} from 'modules/loggedUserInfo/selectors';
import { BOUQUET_MY_EXCO, INVOICE_SEARCH_CARD } from 'const/excoServices';
import clsx from 'clsx';
import { SectionTitle } from 'components/Section/SectionTitle';
import MainStyles from 'assets/styles/main.module.css';
import { APP_MODULES, ROUTES } from 'const';
import { useChartFilters } from 'hooks/useChartFilters';
import { Button } from 'components/ui/Button';
import { NavLink } from 'react-router-dom';
import { CHARTS_VIEW_SWITCH_TYPES } from 'const/ui';
import { Section } from 'components/Section';
import { HomePageAdvantages } from 'components/HomePageAdvantages';
import { ServiceCardDefault } from 'components/ServiceCard/ServiceCardDefault';
import UsefulLinks from 'components/UsefulLinks';
import { userFinancialsDataRequest } from 'modules/loggedUserInfo/actions';
import { useServiceCards } from 'hooks/useServiceCards';

import { LastNews } from 'containers/LastNews';

import Styles from './HomePage.module.css';
import { IndicatorsSection } from './IndicatorsSection';

const {
  SERVICES_SECTION_TITLE,
  BUTTONS_TEXT,
  ERROR_DEACTIVATED_COMPANY,
  MY_USEFUL_LINKS,
  ADD_LINK,
} = HOMEPAGE_FORM_TRANSLATIONS;
const { YEAR } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const { BAR } = CHARTS_VIEW_SWITCH_TYPES;

const { DASHBOARD_FIN, DASHBOARD_HR } = APP_MODULES;

export const HomePage = () => {
  const dispatch = useDispatch();

  const userModules = useSelector(selectUserAuthorizedModules);
  const isMainUser = useSelector(selectIsMainUser);
  const [isUsefulLinksModalVisible, setUsefulLinksModalVisibility] = useState(
    false
  );

  const openUsefulLinksModal = () => {
    setUsefulLinksModalVisibility(true);
  };

  const closeUsefulLinksModal = () => {
    setUsefulLinksModalVisibility(false);
  };

  const { setActiveDateViewOption, setActiveYear } = useChartFilters(YEAR, BAR);

  const { currentExercice, currentYearFromExercice } = useSelector(
    selectUserCurrentCompanyExerciceWithYear
  );
  const currentCompanyId = useSelector(selectUserCurrentCompanyDosId);
  const isCurrentCompanyActivated = useSelector(
    selectUserCurrentCompanyActivatedStatus
  );

  useEffect(() => {
    if (
      [DASHBOARD_FIN, DASHBOARD_HR].some((module) =>
        userModules.includes(module)
      )
    ) {
      dispatch(
        userFinancialsDataRequest({
          exercice: currentExercice,
          companyId: currentCompanyId,
        })
      );
    }
  }, [userModules, currentExercice, currentCompanyId, dispatch]);

  useEffect(() => {
    setActiveYear(Number(currentYearFromExercice));
    setActiveDateViewOption(YEAR);
  }, [
    currentCompanyId,
    currentYearFromExercice,
    setActiveYear,
    setActiveDateViewOption,
  ]);

  const userCurrentPackages = useSelector(selectUserPackagesNames);

  const isMyExcoPackageActive = useMemo(
    () => userCurrentPackages.includes(BOUQUET_MY_EXCO.PACKAGE_NAME),
    [userCurrentPackages]
  );

  const canUserSeeDashboard = [DASHBOARD_FIN, DASHBOARD_HR].some((module) =>
    userModules.includes(module)
  );

  const serviceCards = useServiceCards();

  return (
    <Row>
      <Col span={24} className={Styles.lastNewsSection}>
        <LastNews />
      </Col>
      {canUserSeeDashboard && isCurrentCompanyActivated && (
        <Col span={24} className={Styles.dashboardSection}>
          <IndicatorsSection
            userModules={userModules}
            currentExercice={currentExercice}
            currentYearFromExercice={currentYearFromExercice}
            currentCompanyId={currentCompanyId}
          />
        </Col>
      )}
      <Col span={24} className={Styles.serviceCardsBackground}>
        <div className={MainStyles.container}>
          <div className={Styles.servicesSectionHeader}>
            <SectionTitle title={MY_USEFUL_LINKS} />
            <Button variant="secondary" onClick={openUsefulLinksModal}>
              {ADD_LINK}
            </Button>
          </div>
          <div className={Styles.serviceCardsWrapper}>
            <UsefulLinks
              isVisible={isUsefulLinksModalVisible}
              closeModal={closeUsefulLinksModal}
              openModal={openUsefulLinksModal}
            />
          </div>
        </div>
      </Col>
      {isCurrentCompanyActivated ? (
        <Col span={24} className={Styles.serviceCardsBackground}>
          <div className={MainStyles.container}>
            <div className={Styles.servicesSectionHeader}>
              <SectionTitle title={SERVICES_SECTION_TITLE} />
              {isMainUser && (
                <NavLink to={ROUTES.STORE}>
                  <Button variant="secondary">
                    {BUTTONS_TEXT.ACCESS_STORE}
                  </Button>
                </NavLink>
              )}
            </div>
            <div className={Styles.serviceCardsWrapper}>
              {serviceCards}
              {isMyExcoPackageActive && (
                <ServiceCardDefault
                  isActive
                  serviceCardObject={INVOICE_SEARCH_CARD}
                />
              )}
            </div>
          </div>
        </Col>
      ) : (
        <Col span={24} className={Styles.serviceCardsBackground}>
          <div
            className={clsx(
              MainStyles.container,
              Styles.deactivatedCompanyMessage
            )}
          >
            {ERROR_DEACTIVATED_COMPANY}
          </div>
        </Col>
      )}
      <Col span={24} className={Styles.advantagesBackground}>
        <Section title={HOMEPAGE_FORM_TRANSLATIONS.ADVANTAGES_SECTION_TITLE}>
          <HomePageAdvantages />
        </Section>
      </Col>
    </Row>
  );
};
