import { fromJS } from 'immutable';
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'const';
import moment from 'moment';
import {
  SET_CALENDAR_MONTH_YEAR_TOGGLE,
  SET_CALENDAR_EMPLOYEE,
  CREATE_MULTIPLE_VACATIONS_SUCCESS,
  CREATE_VACATION_SUCCESS,
  DELETE_LEAVE_SUCCESS,
  EDIT_LEAVE_SUCCESS,
  GET_CALENDAR_ABSENCES_ERROR,
  GET_CALENDAR_ABSENCES_REQUEST,
  GET_CALENDAR_ABSENCES_SUCCESS,
  SET_CALENDAR_ACTIVE_BRANCH,
  SET_CALENDAR_DATE_PICKER_VALUE,
  SET_CALENDAR_SEARCH_INPUT_VALUE,
  SET_CALENDAR_WITH_ABSENCES_ONLY,
  GET_HOLIDAYS_REQUEST,
  GET_HOLIDAYS_SUCCESS,
  GET_HOLIDAYS_ERROR,
  GET_ABSENCES_TYPES_SUCCESS,
  CREATE_VACATION_REQUEST_ERROR,
} from 'modules/SalaryModule/Calendar/actions';
import {
  EDIT_LEAVE_REQUEST,
  CREATE_VACATION_REQUEST,
} from 'modules/SalaryModule/Vacations/actions';

import { HOMEPAGE_FORM_TRANSLATIONS } from 'const/translations';
const { MONTH } = HOMEPAGE_FORM_TRANSLATIONS.YEAR_MONTH_SWITCH;

const initialState = fromJS({
  monthYearToggle: MONTH,
  calendarEmployee: undefined,
  absences: [],
  absencesStatus: STATUS_NOT_REQUESTED,
  absencesError: {},
  error: {},
  searchFilter: '',
  dateFilter: moment().startOf('month'),
  branchFilter: '',
  withAbsencesOnly: false,
  areHolidaysUpdating: false,
  yearsWithoutHolidays: [],
  absencesTypes: [],
});

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALENDAR_MONTH_YEAR_TOGGLE: {
      const { value } = action.payload;
      return state.setIn(['monthYearToggle'], value);
    }
    case SET_CALENDAR_EMPLOYEE: {
      const { value } = action.payload;
      return state.setIn(['calendarEmployee'], value);
    }
    case SET_CALENDAR_SEARCH_INPUT_VALUE: {
      const { value } = action.payload;
      return state.setIn(['searchFilter'], value);
    }
    case SET_CALENDAR_ACTIVE_BRANCH: {
      const { branchId } = action.payload;
      return state
        .setIn(['branchFilter'], branchId)
        .setIn(['calendarEmployee'], undefined);
    }
    case SET_CALENDAR_DATE_PICKER_VALUE: {
      const { date } = action.payload;
      return state
        .setIn(['dateFilter'], date)
        .setIn(['absencesStatus'], STATUS_LOADING);
    }
    case SET_CALENDAR_WITH_ABSENCES_ONLY: {
      const { value } = action.payload;
      return state.setIn(['withAbsencesOnly'], value);
    }
    case EDIT_LEAVE_REQUEST: {
      const { leaveObj } = action.payload;
      return state.updateIn(['absences'], (absences) => {
        const index = absences.findIndex(
          (absence) => leaveObj.id === absence.get('id')
        );

        return absences.setIn([index], fromJS(leaveObj));
      });
    }
    case CREATE_VACATION_REQUEST: {
      const { data, employeeId, file } = action.payload;
      return state.updateIn(['absences'], (absences) => {
        const supportingDoc = file ? { name: file.name } : {};
        return fromJS([
          ...absences.toJS(),
          { ...data, employeeId, supportingDoc },
        ]);
      });
    }
    case CREATE_VACATION_REQUEST_ERROR: {
      return state.updateIn(['absences'], (absences) =>
        fromJS([...absences.toJS().filter((el) => el.id)])
      );
    }
    case EDIT_LEAVE_SUCCESS: {
      const { leaveObj } = action.payload;
      return state.updateIn(['absences'], (absences) => {
        const index = absences.findIndex(
          (absence) =>
            leaveObj.id === absence.get('id') &&
            leaveObj.employeeId === absence.get('employeeId')
        );

        return absences.mergeIn([index], fromJS(leaveObj));
      });
    }
    case CREATE_VACATION_SUCCESS: {
      const {
        leaveObj: {
          data: { absence: newAbsence, absences: newAbsences },
        },
      } = action.payload;

      if (newAbsence) {
        return state.updateIn(['absences'], (absences) =>
          absences.filter((a) => a.get('id')).push(fromJS(newAbsence))
        );
      }

      if (newAbsences) {
        return state.updateIn(['absences'], (absences) =>
          absences.filter((a) => a.get('id')).push(...fromJS(newAbsences))
        );
      }

      return state;
    }
    case CREATE_MULTIPLE_VACATIONS_SUCCESS: {
      const { leaveObjArray } = action.payload;

      return state.updateIn(['absences'], (absences) =>
        absences.push(...fromJS(leaveObjArray))
      );
    }
    case DELETE_LEAVE_SUCCESS: {
      const { leaveObj } = action.payload;
      return state.updateIn(['absences'], (absences) =>
        fromJS(absences.toJS().filter(({ id }) => id !== leaveObj.id))
      );
    }
    case GET_CALENDAR_ABSENCES_REQUEST: {
      return state.setIn(['absencesStatus'], STATUS_LOADING);
    }
    case GET_CALENDAR_ABSENCES_SUCCESS: {
      const {
        data: { absences },
      } = action.payload;
      return state
        .setIn(['absences'], fromJS(absences))
        .setIn(['absencesStatus'], STATUS_SUCCESS);
    }
    case GET_CALENDAR_ABSENCES_ERROR: {
      const { error } = action.payload;
      return state
        .setIn(['absencesStatus'], STATUS_ERROR)
        .setIn(['absences'], initialState.getIn(['absences']))
        .setIn(['absencesError'], fromJS(error));
    }
    case GET_HOLIDAYS_REQUEST: {
      return state.setIn(['areHolidaysUpdating'], true);
    }
    case GET_HOLIDAYS_SUCCESS: {
      return state.setIn(['areHolidaysUpdating'], false);
    }
    case GET_HOLIDAYS_ERROR: {
      return state
        .setIn(['areHolidaysUpdating'], false)
        .mergeIn(['yearsWithoutHolidays'], action.payload);
    }
    case GET_ABSENCES_TYPES_SUCCESS: {
      return state.setIn(['absencesTypes'], action.payload);
    }
    default:
      return state;
  }
};
