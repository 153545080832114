import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_LIST_OF_PUBLICATIONS,
  getListOfPublicationsSuccess,
  GET_LIST_OF_PUBLICATIONS_TAGS,
  getListOfPublicationsTagsSuccess,
  GET_PUBLICATION,
  getPublicationSuccess,
} from '../actions';
import {
  getListOfPublicationsRequest,
  getListOfPublicationsTagsRequest,
  getPublicationRequest,
} from '../services';

function* getListOfPublicationsWorker({ payload: { page, limit, tags } }) {
  try {
    const news = yield call(getListOfPublicationsRequest, {
      page,
      limit,
      tags,
    });
    yield put(getListOfPublicationsSuccess(news));
  } catch (error) {
    console.warn(error);
  }
}

function* getListOfPublicationsTagsWorker() {
  try {
    const tags = yield call(getListOfPublicationsTagsRequest);
    yield put(getListOfPublicationsTagsSuccess(tags));
  } catch (error) {
    console.warn(error);
  }
}

function* getPublication({ payload: id }) {
  try {
    const publication = yield call(getPublicationRequest, id);
    yield put(getPublicationSuccess(publication));
  } catch (error) {
    console.warn(error);
  }
}

export function* newsMainSaga() {
  yield takeLatest(GET_LIST_OF_PUBLICATIONS, getListOfPublicationsWorker);
  yield takeLatest(
    GET_LIST_OF_PUBLICATIONS_TAGS,
    getListOfPublicationsTagsWorker
  );
  yield takeLatest(GET_PUBLICATION, getPublication);
}
