import { request } from 'utils/request';

export const fetchExcoInfoModalData = ({ companyId }) =>
  request.get(`/companies/${companyId}/pending-changes`);

export const finalizeExcoData = ({ month, year, comment, companyId }) =>
  request.post(`/companies/${companyId}/pending-changes`, {
    month,
    year,
    comment,
  });

export const fetchCheckPendingChanges = ({ companyId, year, month }) =>
  request.get(`/companies/${companyId}/check-pending-changes`, {
    params: {
      year,
      month,
    },
  });
