import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Styles from './SmallTag.module.css';

const SMALL_TAG_MAX_LENGTH = 34;

export const SmallTag = ({ id, label, toggleTag, isActiveTag }) => {
  const tagOnClick = useCallback(() => (id ? toggleTag(id) : () => {}), [
    id,
    toggleTag,
  ]);

  return (
    <div
      key={id}
      className={clsx(Styles.newsCategory, {
        [Styles.categoryActive]: isActiveTag,
      })}
      onClick={tagOnClick}
      {...(label?.length > SMALL_TAG_MAX_LENGTH ? { title: label } : {})}
    >
      {label}
    </div>
  );
};

SmallTag.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  toggleTag: PropTypes.func,
  isActiveTag: PropTypes.bool,
};
