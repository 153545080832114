import React, { useEffect, memo } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { selectCompanyRewardsFamilies } from 'modules/SalaryModule/Rewards/selectors';
import { selectUserCompanyId } from '../../../modules/loggedUserInfo/selectors';
import { setActiveBranch } from '../../../modules/SalaryModule/CompanySettings/actions';
import { useQuery } from '../../../modules/SalaryModule/CompanySettings/hooks/useQuery.hook';
import {
  selectActiveBranchData,
  selectBranches,
  selectCompanyDetailData,
} from '../../../modules/SalaryModule/CompanySettings/selectors';
import { isCurrentBranchInOptions } from '../../../modules/SalaryModule/CompanySettings/utils';
import { getCompanyRewardsFamilies } from '../../../modules/SalaryModule/Rewards/actions';
import { CSManageRewardFamilies } from './CSManageRewardFamilies';
import Styles from './CompanySettingsPage.module.css';
import { PageTitle } from '../../../components/SalaryComponents/PageTitle';
import {
  COMPANY_SETTINGS_TABS,
  COMPANY_SETTINGS,
} from '../../../const/translations/SalaryCompanySettings';
import { CompanySettingsForm } from './CompanySettingsForm';
import { ScheduleTable } from './ScheduleTable';

const { TabPane } = Tabs;

export const CompanySettingsPage = memo(() => {
  const dispatch = useDispatch();
  const companyId = useSelector(selectUserCompanyId);
  const query = useQuery();
  const tabNumber = query.get('tab');
  const companyDetailData = useSelector(selectCompanyDetailData);
  const branches = useSelector(selectBranches);
  const activeBranchData = useSelector(selectActiveBranchData);
  const families = useSelector(selectCompanyRewardsFamilies);

  useScrollToTop();

  useEffect(() => {
    dispatch(getCompanyRewardsFamilies({ companyId }));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (
      companyDetailData &&
      (!activeBranchData ||
        !isCurrentBranchInOptions(activeBranchData.siret, branches))
    ) {
      const activeBranch = branches[0] || null;
      dispatch(setActiveBranch(activeBranch));
    }
  }, [dispatch, companyDetailData, activeBranchData, branches]);

  return (
    <div className={Styles.container}>
      <PageTitle>{COMPANY_SETTINGS.PAGE_TITLE}</PageTitle>
      <Tabs defaultActiveKey={tabNumber || '1'} className={Styles.tabBar}>
        <TabPane tab={COMPANY_SETTINGS_TABS.GENERAL_INFORMATION} key="1">
          <CompanySettingsForm />
        </TabPane>
        <TabPane tab={COMPANY_SETTINGS_TABS.WORKING_HOURS} key="2">
          <ScheduleTable />
        </TabPane>
        {families?.length && (
          <TabPane tab={COMPANY_SETTINGS_TABS.VARIABLE_ITEM_TITLES} key="3">
            <CSManageRewardFamilies />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
});
