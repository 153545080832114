import { request } from 'utils/request';

export const getCompanyDetail = (companyId) =>
  request.get(`/companies/${companyId}/detailed/`);

export const getCompanyBranches = (companyId) =>
  request.get(`/companies/${companyId}/branches/`);

export const getSchedules = (companyId) =>
  request.get(`/companies/${companyId}/schedules/`);

export const getScheduleDetail = (payload) =>
  request.get(
    `/companies/${payload.companyId}/schedules/${payload.scheduleId}${
      payload.siret ? `?siret=${payload.siret}` : ''
    }`
  );

export const updateSchedule = (payload) =>
  request.put(
    `/companies/${payload.companyId}/schedules/${payload.scheduleId}`,
    payload.data
  );

export const deleteSchedule = (payload) =>
  request.delete(
    `/companies/${payload.companyId}/schedules/${payload.scheduleId}`
  );

export const createSchedule = (payload) =>
  request.post(`/companies/${payload.companyId}/schedules`, payload.data);

export const createBranch = (companyId, data) =>
  request.post(`/companies/${companyId}/branches`, data);

export const updateBranch = (companyId, siret, data) =>
  request.put(`/companies/${companyId}/branches/${siret}`, data);

export const deleteBranch = (companyId, siret) =>
  request.delete(`/companies/${companyId}/branches/${siret}`);

export const refreshSilaeEmployeesRequest = (companyId) =>
  request.get(`/companies/${companyId}/sync/silae`);

export const fetchCompanySynchronizationRequest = (companyId) =>
  request.get(`/companies/${companyId}/check/sync/silae`);
