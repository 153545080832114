import React, { memo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ROUTES } from 'const';

import { ReactComponent as ArrowRight } from 'assets/icons/news-arrow-right.svg';
import newsThumbnail from 'assets/icons/news-thumbnail.svg';

import Styles from './NewsSmallCard.module.css';

export const NewsSmallCard = memo(({ id, thumbnail, title, date, summary }) => (
  <Link
    key={id}
    to={`${ROUTES.NEWS}/${id}?fallback=${ROUTES.ROOT}`}
    className={Styles.newsCard}
  >
    <div className={Styles.newsThumbnailWrapper}>
      <img
        src={thumbnail || newsThumbnail}
        alt="thumbnail"
        className={clsx(Styles.newsThumbnail, {
          [Styles.newsEmptyThumbnail]: !thumbnail,
        })}
      />
    </div>
    <div className={Styles.newsCardContent}>
      <span className={Styles.newsDate}>
        {moment(date).format('DD MMM YYYY')}
      </span>
      <span className={Styles.newsTitleWrapper}>
        <span className={Styles.newsTitle}>{title}</span>
        <ArrowRight className={Styles.arrowIcon} />
      </span>
      <span
        className={Styles.newsShortContent}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: summary }}
      />
    </div>
  </Link>
));

NewsSmallCard.propTypes = {
  id: PropTypes.number.isRequired,
  thumbnail: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};
