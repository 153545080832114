import PropTypes from 'prop-types';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Icon, Typography } from 'antd';
import { MonthPicker } from 'components/ui/MonthPicker';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeRewardsCsvCommonFilter } from 'modules/SalaryModule/RewardsTable/utils';
import { getCompanyRewardsFamilies } from 'modules/SalaryModule/Rewards/actions';
import {
  downloadEmployeesRewardsXlsx,
  getEmployeeRewards,
  setEmployeeRewardsSearch,
} from 'modules/SalaryModule/RewardsTable/actions';
import {
  REWARDS_GENERAL_TAB,
  SALARY_REWARDS_PAGE,
} from 'const/SalaryRewardsPage';
import { REWARDS_PAGE } from 'const/translations/SalaryRewardsPage';
import { selectBranchesOptions } from 'modules/SalaryModule/CompanySettings/selectors';
import { selectCompanyRewardsFamilies } from 'modules/SalaryModule/Rewards/selectors';
import { getCheckPendingChanges } from 'modules/SalaryModule/SalaryModal/actions';
import {
  selectEmployeeSearch,
  selectEmployeeRewardsMeta,
} from 'modules/SalaryModule/RewardsTable/selectors';
import { selectIsSyncing } from 'modules/SalaryModule/Employees/selectors';
import { useSimpleMedia } from 'hooks/useMedia';
import { Button, CustomSelect, Input } from 'components/ui';

import { REWARDS_TABLE_ITEM_PER_PAGE } from 'containers/Salary/RewardsPage/RewardsTable';
import Styles from './RewardsToolbar.module.css';
import { RewardsFamiliesTabs } from './RewardsFamiliesTabs';
import { SendInfoButton } from '../SendInfoButton';

const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SALARY_REWARDS_PAGE.SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const propTypes = {
  companyId: PropTypes.string,
  activeMonth: PropTypes.object,
  setActiveMonth: PropTypes.func,
  employeeIdXlsx: PropTypes.string,
  setEmployeeIdXlsx: PropTypes.func,

  activeFamily: PropTypes.any,
  selectedBranch: PropTypes.any,
  setActiveFamily: PropTypes.any,
  setSelectedBranch: PropTypes.any,
  currentPage: PropTypes.any,
  setCurrentPage: PropTypes.any,
};

/**
 * @param employeeIdCsv {string} - employee`s id whose csv file is going to be downloaded
 * @param setEmployeeIdCsv {function} - sets employee`s id whose csv file is going to be downloaded
 * */
export const RewardsToolbar = memo(
  ({
    activeMonth,
    setActiveMonth,
    companyId,
    employeeIdXlsx,
    setEmployeeIdXlsx,
    activeFamily,
    selectedBranch,
    setActiveFamily,
    setSelectedBranch,
    currentPage,
    setCurrentPage,
  }) => {
    const employeeSearch = useSelector(selectEmployeeSearch);
    const branchesOptions = useSelector(selectBranchesOptions);
    const families = useSelector(selectCompanyRewardsFamilies);
    const employeesRewardsMeta = useSelector(selectEmployeeRewardsMeta);
    const isSyncing = useSelector(selectIsSyncing);

    const [isInputVisible, setInputVisibility] = useState(false);

    const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
    const searchRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
      if (branchesOptions.length === 1) {
        setSelectedBranch(branchesOptions[0].value);
      }
    }, [branchesOptions, setSelectedBranch]);

    const handleSearchChange = useCallback(
      (e) => {
        dispatch(setEmployeeRewardsSearch(e.target.value));
        setCurrentPage(1);
      },
      [dispatch, setCurrentPage]
    );

    const toggleSearchVisibility = useCallback(() => {
      setInputVisibility((prev) => !prev);
    }, []);

    const handleBranchChange = useCallback(
      (_, data) => {
        setSelectedBranch(data);
        setCurrentPage(1);
      },
      [setSelectedBranch, setCurrentPage]
    );

    const downloadTableXlsxHandler = useCallback(() => {
      dispatch(
        downloadEmployeesRewardsXlsx({
          companyId,
          filter: getEmployeeRewardsCsvCommonFilter(
            selectedBranch || '',
            activeFamily,
            activeMonth,
            employeeSearch,
            employeeIdXlsx || ''
          ),
        })
      );
    }, [
      dispatch,
      employeeIdXlsx,
      selectedBranch,
      activeFamily,
      activeMonth,
      employeeSearch,
      companyId,
    ]);

    useEffect(() => {
      if (employeeIdXlsx) {
        downloadTableXlsxHandler();
        setEmployeeIdXlsx(null);
      }
    }, [employeeIdXlsx, downloadTableXlsxHandler, setEmployeeIdXlsx]);

    useEffect(() => {
      if (families) {
        setActiveFamily((prevFamily) => {
          if (
            families.find(({ id }) => id === prevFamily) ||
            prevFamily === REWARDS_GENERAL_TAB.ID
          ) {
            return prevFamily;
          }

          return (
            families.find(({ rewardsCount }) => rewardsCount > 0)?.id || ''
          );
        });
      } else {
        setActiveFamily((prevFamily) =>
          prevFamily === REWARDS_GENERAL_TAB.ID ? prevFamily : ''
        );
      }
    }, [families, setActiveFamily]);

    useEffect(() => {
      if (isInputVisible && isMobileVersion) {
        searchRef.current.focus();
      } else {
        dispatch(setEmployeeRewardsSearch(''));
      }
    }, [dispatch, isMobileVersion, isInputVisible]);

    useEffect(() => {
      dispatch(
        getCompanyRewardsFamilies({
          companyId,
          year: activeMonth.year(),
          month: activeMonth.month() + 1,
        })
      );
    }, [dispatch, companyId, activeMonth]);

    const lastPage = useMemo(() => employeesRewardsMeta?.pages || 1, [
      employeesRewardsMeta,
    ]);
    const isNeedToChangePage = useMemo(() => currentPage > lastPage, [
      currentPage,
      lastPage,
    ]);

    /**
     * Families must be present, else request ends with 403 error on company change.
     */
    useEffect(() => {
      if (activeMonth && activeFamily && companyId) {
        if (isNeedToChangePage) {
          setCurrentPage(1);
          return;
        }

        dispatch(
          getCheckPendingChanges({
            companyId,
            month: activeMonth.month() + 1,
            year: activeMonth.year(),
          })
        );

        dispatch(
          getEmployeeRewards({
            companyId,
            siret: selectedBranch || '',
            rewardFamilyId: activeFamily,
            year: activeMonth.year(),
            month: activeMonth.month() + 1,
            limit: REWARDS_TABLE_ITEM_PER_PAGE,
            page: currentPage,
          })
        );
      }
    }, [
      dispatch,
      activeMonth,
      selectedBranch,
      activeFamily,
      companyId,
      setCurrentPage,
      isNeedToChangePage,
      currentPage,
      isSyncing,
    ]);

    return (
      <div className={Styles.wrapper}>
        <div className={Styles.headerRow}>
          <Typography.Title className={Styles.pageTitle}>
            {REWARDS_PAGE.TITLE}
          </Typography.Title>
          {!isMobileVersion && <SendInfoButton cancelHide />}
          {isMobileVersion && (
            <div
              className={Styles.searchIconWrapper}
              onClick={toggleSearchVisibility}
            >
              <Icon type="search" className={Styles.searchIcon} />
            </div>
          )}
        </div>
        <div className={Styles.filtersWrapper}>
          <div className={Styles.filtersRow}>
            <MonthPicker value={activeMonth} setValue={setActiveMonth} />
            <CustomSelect
              size="large"
              name="branches"
              className={Styles.select}
              options={branchesOptions}
              onChange={handleBranchChange}
              value={selectedBranch}
              placeholder={REWARDS_PAGE.BRANCH_PLACEHOLDER}
              allowClear
            />
            {((isMobileVersion && isInputVisible) || !isMobileVersion) && (
              <Input
                size="large"
                allowClear
                placeholder={REWARDS_PAGE.SEARCH_PLACEHOLDER}
                prefix={
                  <Icon type="search" className={Styles.searchIconPrefix} />
                }
                value={employeeSearch}
                onChange={handleSearchChange}
                className={Styles.searchInput}
                ref={searchRef}
              />
            )}
          </div>
          {!isMobileVersion && (
            <Button
              sizeAuto
              variant="transparent"
              className={Styles.downloadButton}
              onClick={downloadTableXlsxHandler}
            >
              <Icon type="download" className={Styles.downloadIcon} />
              {REWARDS_PAGE.DOWNLOAD_BTN}
            </Button>
          )}
        </div>

        <RewardsFamiliesTabs
          families={families || []}
          isMobileVersion={isMobileVersion}
          activeFamily={activeFamily}
          setActiveFamily={setActiveFamily}
        />
      </div>
    );
  }
);

RewardsToolbar.propTypes = propTypes;
