import { createSelector } from 'reselect';

export const selectNews = createSelector(
  (state) => state.getIn(['newsReducer', 'news']),
  (news) => (news ? news.toJS() : {})
);

export const selectIsNewsLoading = (state) =>
  state.getIn(['newsReducer', 'isNewsLoading']);

export const selectLastNews = createSelector(selectNews, ({ data }) =>
  data ? data.slice(0, 2) : []
);

export const selectNewsTags = createSelector(
  (state) => state.getIn(['newsReducer', 'hashtags']),
  (tags) => (tags ? tags.toJS() : {}).data || []
);

export const selectPublication = createSelector(
  (state) => state.getIn(['newsReducer', 'publication']),
  (publication) => (publication ? publication.toJS() : {})
);
