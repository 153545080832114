import { getActionCreator, getType } from 'utils';
const prefix = '@@app/calendar';

export const SET_CALENDAR_MONTH_YEAR_TOGGLE = getType(
  prefix,
  'SET_CALENDAR_MONTH_YEAR_TOGGLE'
);
export const setMonthYearToggleValue = getActionCreator(
  SET_CALENDAR_MONTH_YEAR_TOGGLE
);

export const SET_CALENDAR_EMPLOYEE = getType(prefix, 'SET_CALENDAR_EMPLOYEE');
export const setCalendarEmployeeValue = getActionCreator(SET_CALENDAR_EMPLOYEE);

export const SET_CALENDAR_DATE_PICKER_VALUE = getType(
  prefix,
  'SET_CALENDAR_DATE_PICKER_VALUE'
);
export const setCalendarDatePickerValue = getActionCreator(
  SET_CALENDAR_DATE_PICKER_VALUE
);

export const SET_CALENDAR_SEARCH_INPUT_VALUE = getType(
  prefix,
  'SET_CALENDAR_SEARCH_INPUT_VALUE'
);
export const setCalendarSearchInputValue = getActionCreator(
  SET_CALENDAR_SEARCH_INPUT_VALUE
);

export const SET_CALENDAR_ACTIVE_BRANCH = getType(
  prefix,
  'SET_CALENDAR_ACTIVE_BRANCH'
);
export const setCalendarActiveBranch = getActionCreator(
  SET_CALENDAR_ACTIVE_BRANCH
);

export const SET_CALENDAR_WITH_ABSENCES_ONLY = getType(
  prefix,
  'SET_CALENDAR_WITH_ABSENCES_ONLY'
);
export const setCalendarWithAbsencesOnly = getActionCreator(
  SET_CALENDAR_WITH_ABSENCES_ONLY
);

export const GET_CALENDAR_ABSENCES_REQUEST = getType(
  prefix,
  'GET_CALENDAR_ABSENCES_REQUEST'
);
export const getCalendarAbsencesRequest = getActionCreator(
  GET_CALENDAR_ABSENCES_REQUEST
);

export const GET_CALENDAR_ABSENCES_SUCCESS = getType(
  prefix,
  'GET_CALENDAR_ABSENCES_SUCCESS'
);
export const getCalendarAbsencesSuccess = getActionCreator(
  GET_CALENDAR_ABSENCES_SUCCESS
);

export const GET_CALENDAR_ABSENCES_ERROR = getType(
  prefix,
  'GET_CALENDAR_ABSENCES_ERROR'
);
export const getCalendarAbsencesError = getActionCreator(
  GET_CALENDAR_ABSENCES_ERROR
);

export const CREATE_MULTIPLE_VACATIONS_SUCCESS = getType(
  prefix,
  'CREATE_MULTIPLE_VACATIONS_SUCCESS'
);
export const createMultipleVacationsSuccess = getActionCreator(
  CREATE_MULTIPLE_VACATIONS_SUCCESS
);

export const CREATE_VACATION_SUCCESS = getType(
  prefix,
  'CREATE_VACATION_SUCCESS'
);
export const createVacationSuccess = getActionCreator(CREATE_VACATION_SUCCESS);

export const CREATE_VACATION_REQUEST_ERROR = getType(
  prefix,
  'CREATE_VACATION_REQUEST_ERROR'
);
export const createVacationError = getActionCreator(
  CREATE_VACATION_REQUEST_ERROR
);

export const EDIT_LEAVE_SUCCESS = getType(prefix, 'EDIT_LEAVE_SUCCESS');
export const editLeaveSuccess = getActionCreator(EDIT_LEAVE_SUCCESS);

export const DELETE_LEAVE_SUCCESS = getType(prefix, 'DELETE_LEAVE_SUCCESS');
export const deleteLeaveSuccess = getActionCreator(DELETE_LEAVE_SUCCESS);

export const DOWNLOAD_ABSENCES = getType(prefix, 'DOWNLOAD_ABSENCES');
export const downloadAbsences = getActionCreator(DOWNLOAD_ABSENCES);

export const GET_HOLIDAYS_REQUEST = getType(prefix, 'GET_HOLIDAYS_REQUEST');
export const getHolidaysRequest = getActionCreator(GET_HOLIDAYS_REQUEST);

export const GET_HOLIDAYS_SUCCESS = getType(prefix, 'GET_HOLIDAYS_SUCCESS');
export const getHolidaysSuccess = getActionCreator(GET_HOLIDAYS_SUCCESS);

export const GET_HOLIDAYS_ERROR = getType(prefix, 'GET_HOLIDAYS_ERROR');
export const getHolidaysError = getActionCreator(GET_HOLIDAYS_ERROR);

export const GET_ABSENCES_TYPES_REQUEST = getType(
  prefix,
  'GET_ABSENCES_TYPES_REQUEST'
);
export const getAbsencesTypesRequest = getActionCreator(
  GET_ABSENCES_TYPES_REQUEST
);

export const GET_ABSENCES_TYPES_SUCCESS = getType(
  prefix,
  'GET_ABSENCES_TYPES_SUCCESS'
);
export const getAbsencesTypesSuccess = getActionCreator(
  GET_ABSENCES_TYPES_SUCCESS
);
