/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import { history } from 'routing/history';
import { turnoverPage } from 'modules/turnoverPage/reducers';
import { treasuryPage } from 'modules/treasuryPage/reducers';
import { commentsModal } from 'modules/commentsModal/reducers';
import { calendarReducer } from 'modules/SalaryModule/Calendar/reducers';
import { HRPage } from 'modules/HRPage/reducers';
import { cartReducer } from 'modules/cart/reducers';
import { LoginPage } from 'modules/LoginPage/reducers';
import { documentsCabinet } from 'modules/documentsCabinet/reducers';
import { employeesReducer } from 'modules/SalaryModule/Employees/reducers';
import { requestHelp } from 'modules/helpModal/reducers';
import { modalContainer } from 'modules/ModalContainer/reducers';
import { employeeRegister } from 'modules/employeeRegister/reducers';
import { companySettingsReducer } from 'modules/SalaryModule/CompanySettings/reducers';
import { salaryModuleReducer } from 'modules/SalaryModule/SalaryModal/reducers';
import { rewardsReducer } from 'modules/SalaryModule/Rewards/reducers';
import { rewardsTableReducer } from 'modules/SalaryModule/RewardsTable/reducers';
import { vacationsReducer } from 'modules/SalaryModule/Vacations/reducers';
import { uploadDocuments } from 'modules/uploadDocuments/reducers';
import { loggedUserInfo } from 'modules/loggedUserInfo/reducers';
import { invoiceSearch } from 'modules/invoiceSearch/reducers';
import { debtors } from 'modules/Debtors/reducers';
import { creditors } from 'modules/Creditors/reducers';
import { dashboardIndicators } from 'modules/dashboardIndicators/reducers';
import { bookmarksReducer } from 'modules/usefulLinks/reducers';
import { newsReducer } from 'modules/News/reducers';
import {
  authError,
  passwordRecovery,
  passwordChangeError,
} from '../Authentication/reducers';

/**
 * Creates the main reducer
 */
export function createReducer() {
  return combineReducers({
    router: connectRouter(history),
    employeeRegister,
    uploadDocuments,
    loggedUserInfo,
    invoiceSearch,
    authError,
    passwordRecovery,
    passwordChangeError,
    documentsCabinet,
    salaryModuleReducer,
    turnoverPage,
    treasuryPage,
    employeesReducer,
    companySettingsReducer,
    vacationsReducer,
    rewardsReducer,
    rewardsTableReducer,
    debtors,
    creditors,
    commentsModal,
    calendarReducer,
    HRPage,
    requestHelp,
    modalContainer,
    cartReducer,
    LoginPage,
    dashboardIndicators,
    bookmarksReducer,
    newsReducer,
  });
}
