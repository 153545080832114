import { createSelector } from 'reselect';

export const selectModalVisibility = (state) =>
  state.getIn(['salaryModuleReducer', 'infoModal', 'isVisible']);

export const selectExcoInfoModalDataStatus = (state) =>
  state.getIn(['salaryModuleReducer', 'status']);

export const selectExcoInfoModalData = createSelector(
  (state) => state.getIn(['salaryModuleReducer', 'infoModal', 'info']),
  (info) => (info ? info.toJS() : {})
);

export const selectExcoInfoModalDataLastFinalizedDate = (state) =>
  state.getIn(['salaryModuleReducer', 'infoModal', 'lastFinalization']);

export const selectIsPendingChanges = (state) =>
  state.getIn(['salaryModuleReducer', 'isPendingChanges']);
