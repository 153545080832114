import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import { setActiveBranch } from 'modules/SalaryModule/CompanySettings/actions';
import { downloadFile } from 'helpers/common';
import { selectActiveBranchData } from 'modules/SalaryModule/CompanySettings/selectors';
import {
  downloadEmployeeTableXLSX,
  getEmployees,
  setEmployeeSchedule,
  fetchDownloadEmployeePayslips,
  fetchDownloadCompanyEmployeesPayslips,
  fetchDownloadCompanyEmployeesPdf,
} from 'modules/SalaryModule/Employees/services';
import {
  CHANGE_EMPLOYEES_FILTERS,
  DOWNLOAD_EMPLOYEES_TABLE_XLSX,
  FETCH_COMPANY_BRANCHES_REQUEST,
  FETCH_EMPLOYEES_REQUEST,
  fetchCompanyBranchesSuccess,
  fetchEmployeesError,
  fetchEmployeesSuccess,
  SET_EMPLOYEE_SCHEDULE,
  setEmployeesFilters,
  setEmployeeScheduleSuccess,
  DOWNLOAD_EMPLOYEE_PAYSLIPS,
  DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS,
  downloadCompanyEmployeesPayslipsSuccess,
  downloadEmployeePayslipsSuccess,
  fetchLastSyncTimeSuccess,
  fetchIsSyncingSuccess,
  REFRESH_SILAE_EMPLOYEES,
  fetchEmployeesRequest,
  fetchCompanyBranchesRequest,
} from 'modules/SalaryModule/Employees/actions';
import {
  SILAE_API_ERRORS,
  SILAE_WARNINGS,
} from 'const/translations/SalaryEmployeePage';
import {
  getCompanyBranches,
  refreshSilaeEmployeesRequest,
  fetchCompanySynchronizationRequest,
} from '../../CompanySettings/services';

import { selectFilters } from '../selectors';
import { getCsvDownloadQuery } from '../utils';

const { NO_DATA } = SILAE_API_ERRORS;
const { EMPLOYEE_TIMEOUT } = SILAE_WARNINGS;

let fetchEmployeeTimeoutId = null;

function* fetchEmployeesWorker({ payload }) {
  try {
    fetchEmployeeTimeoutId = setTimeout(() => {
      notification.warning({ message: EMPLOYEE_TIMEOUT, duration: 10 });
    }, 5000);
    const { data } = yield call(getEmployees, payload);

    yield put(fetchEmployeesSuccess({ data: data.employees }));
    yield put(fetchLastSyncTimeSuccess({ data: data.lastSyncTime }));
    yield put(fetchIsSyncingSuccess({ data: data.syncing }));
    yield clearTimeout(fetchEmployeeTimeoutId);

    if (data.syncing) {
      yield call(
        checkSilaeEmployeesWorker,
        payload.companyId,
        payload.dateStart
      );
    }
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* fetchCompanyBranchesWorker({ payload }) {
  try {
    const { data } = yield call(getCompanyBranches, payload);
    yield put(fetchCompanyBranchesSuccess(data));
    const activeBranch = yield select(selectActiveBranchData);
    if (data.length === 0) {
      yield put(setActiveBranch(null));
    }
    const shouldUpdateActiveBranch =
      !activeBranch ||
      (activeBranch && !data.some(({ siret }) => siret === activeBranch.siret));
    if (shouldUpdateActiveBranch) {
      yield put(setActiveBranch(data[0]));
    }
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* changeEmployeesFiltersWorker({ payload }) {
  try {
    const selectedFilters = yield select(selectFilters);
    const filters = { ...selectedFilters };
    Object.keys(payload).forEach((key) => {
      filters[key] = payload[key];
    });

    yield put(setEmployeesFilters(filters));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* setEmployeeScheduleWorker({ payload }) {
  try {
    const newSchedule = yield call(setEmployeeSchedule, payload);
    yield put(setEmployeeScheduleSuccess({ newSchedule }));
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* downloadEmployeeTableXLSXWorker({ payload: { companyId, filter } }) {
  try {
    const query = getCsvDownloadQuery(filter);

    const { fileData, fileName } = yield call(downloadEmployeeTableXLSX, {
      companyId,
      query,
    });

    yield call(downloadFile, fileData, fileName);
  } catch (error) {
    yield put(fetchEmployeesError({ error }));
  }
}

function* downloadEmployeePayslipsHandler({
  payload: { companyId, employeeId, startDate, endDate, employeeName },
}) {
  try {
    const { fileData, fileName } = yield call(fetchDownloadEmployeePayslips, {
      companyId,
      employeeId,
      startDate,
      endDate,
    });

    const fileNameWithEmployeeName = `${employeeName
      .trim()
      .split(' ')
      .join('_')}_${fileName}`;

    yield call(downloadFile, fileData, fileNameWithEmployeeName);
    yield put(downloadEmployeePayslipsSuccess());
  } catch (error) {
    console.warn(error);

    yield put(downloadEmployeePayslipsSuccess());
    if (error.statusCode === 404) {
      notification.error({
        message: NO_DATA,
      });
    }
  }
}

function* downloadCompanyEmployeesPayslipsHandler({
  payload: { companyId, startDate, endDate, key },
}) {
  try {
    const { fileData, fileName } = yield !key
      ? call(fetchDownloadCompanyEmployeesPayslips, {
          companyId,
          startDate,
          endDate,
        })
      : call(fetchDownloadCompanyEmployeesPdf, {
          companyId,
          startDate,
          endDate,
          key,
        });

    yield call(downloadFile, fileData, fileName);
    yield put(downloadCompanyEmployeesPayslipsSuccess());
  } catch (error) {
    console.warn(error);

    yield put(downloadCompanyEmployeesPayslipsSuccess());
    if (error.statusCode === 404) {
      notification.error({
        message: NO_DATA,
      });
    } else {
      notification.error({
        message: error.error.message,
      });
    }
  }
}

function* refreshSilaeEmployeesWorker({ payload: { companyId, dateRange } }) {
  try {
    const { data } = yield call(refreshSilaeEmployeesRequest, companyId);

    yield put(fetchLastSyncTimeSuccess({ data: data.lastSyncTime }));
    yield put(fetchIsSyncingSuccess({ data: data.syncing }));
    yield call(
      checkSilaeEmployeesWorker,
      companyId,
      dateRange.startDate.format('YYYY-MM-DD')
    );
  } catch (error) {
    notification.error({
      message: error.error,
    });
  }
}

function* checkSilaeEmployeesWorker(companyId, startDate) {
  try {
    yield delay(5000);
    const { data } = yield call(fetchCompanySynchronizationRequest, companyId);

    if (data.syncing) {
      yield call(checkSilaeEmployeesWorker, companyId, startDate);

      return;
    }

    yield put(fetchLastSyncTimeSuccess({ data: data.lastSyncTime }));
    yield put(fetchIsSyncingSuccess({ data: data.syncing }));

    yield put(fetchCompanyBranchesRequest(companyId));
    yield put(
      fetchEmployeesRequest({
        companyId,
        dateStart: startDate,
      })
    );
  } catch (error) {
    notification.error({
      message: error.error,
    });
  }
}

export function* employeesMainSaga() {
  yield takeLatest(FETCH_EMPLOYEES_REQUEST, fetchEmployeesWorker);
  yield takeLatest(CHANGE_EMPLOYEES_FILTERS, changeEmployeesFiltersWorker);
  yield takeLatest(SET_EMPLOYEE_SCHEDULE, setEmployeeScheduleWorker);
  yield takeLatest(FETCH_COMPANY_BRANCHES_REQUEST, fetchCompanyBranchesWorker);
  yield takeLatest(
    DOWNLOAD_EMPLOYEES_TABLE_XLSX,
    downloadEmployeeTableXLSXWorker
  );
  yield takeLatest(DOWNLOAD_EMPLOYEE_PAYSLIPS, downloadEmployeePayslipsHandler);
  yield takeLatest(
    DOWNLOAD_COMPANY_EMPLOYEES_PAYSLIPS,
    downloadCompanyEmployeesPayslipsHandler
  );
  yield takeLatest(REFRESH_SILAE_EMPLOYEES, refreshSilaeEmployeesWorker);
}
