import { takeLatest, put, call } from 'redux-saga/effects';
import {
  SEND_EXCO_INFO_REQUEST,
  sendExcoInfoSuccess,
  GET_EXCO_INFO_MODAL_DATA_REQUEST,
  getExcoInfoModalDataSuccess,
  sendExcoInfoError,
  GET_CHECK_PENDING_CHANGES_REQUEST,
  getCheckPendingChangesSuccess,
  getCheckPendingChanges,
} from 'modules/SalaryModule/SalaryModal/actions';
import {
  fetchExcoInfoModalData,
  finalizeExcoData,
  fetchCheckPendingChanges,
} from '../services';

function* sendExcoInfoWorker({
  payload: { onSuccess = () => {}, date, comment, companyId },
}) {
  try {
    yield call(finalizeExcoData, {
      month: date.month() + 1,
      year: date.year(),
      comment,
      companyId,
    });
    yield put(sendExcoInfoSuccess());
    yield put(
      getCheckPendingChanges({
        companyId,
        month: date.month() + 1,
        year: date.year(),
      })
    );

    yield call(onSuccess);
  } catch (error) {
    yield put(sendExcoInfoError());
    console.warn(error);
  }
}

function* getExcoInfoModalDataWorker({ payload: { companyId } }) {
  try {
    const { data } = yield call(fetchExcoInfoModalData, {
      companyId,
    });
    yield put(getExcoInfoModalDataSuccess({ data: data.updates }));
  } catch (error) {
    console.warn(error);
  }
}

function* getCheckPendingChangesWorker({
  payload: { companyId, year, month },
}) {
  try {
    const { data } = yield call(fetchCheckPendingChanges, {
      companyId,
      year,
      month,
    });

    yield put(getCheckPendingChangesSuccess(data.changesArePended));
  } catch (error) {
    console.warn(error);
  }
}

export function* salaryModalMainSaga() {
  yield takeLatest(SEND_EXCO_INFO_REQUEST, sendExcoInfoWorker);
  yield takeLatest(
    GET_EXCO_INFO_MODAL_DATA_REQUEST,
    getExcoInfoModalDataWorker
  );
  yield takeLatest(
    GET_CHECK_PENDING_CHANGES_REQUEST,
    getCheckPendingChangesWorker
  );
}
