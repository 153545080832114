import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from '@iconify/react';

import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';

import { ROUTES } from 'const';
import {
  NEWS,
  LAST_NEWS_TRANSLATIONS,
  NAVIGATION_LABELS,
} from 'const/translations';

import { getPublication } from 'modules/News/actions';
import { selectNewsTags, selectPublication } from 'modules/News/selectors';

import { SectionTitle } from 'components/Section/SectionTitle';
import { SmallTag } from 'components/Tags/SmallTag';
import GoBackStyles from 'components/SalaryComponents/SalaryBreadcrumbs/SalaryBreadcrumbs.module.css';
import MainStyles from 'assets/styles/main.module.css';
import { Button } from 'components/ui/Button';
import { Spinner } from 'components/Spinner';

import { useScrollToTop } from 'hooks/useScrollToTop';

import Styles from './NewsArticle.module.css';

const { TITLE: GO_BACK_TO_NEWS } = NEWS;
const { ALL_NEWS } = LAST_NEWS_TRANSLATIONS;
const { INDICATORS } = NAVIGATION_LABELS;

export const NewsArticle = ({ match }) => {
  const dispatch = useDispatch();
  const newsTagsList = useSelector(selectNewsTags);
  const { data: articleData } = useSelector(selectPublication);
  const currentPublicationId = match.params.id;

  const isReturnToMainPage =
    new URLSearchParams(document.location.search)?.get('fallback') ===
    ROUTES.ROOT;

  useEffect(() => {
    dispatch(getPublication(currentPublicationId));
  }, [dispatch, currentPublicationId]);

  useScrollToTop();

  if (!articleData || articleData.id !== currentPublicationId) {
    return <Spinner />;
  }

  return (
    <div className={Styles.background}>
      <div className={clsx(Styles.dashboardHeader, MainStyles.container)}>
        <NavLink
          to={isReturnToMainPage ? ROUTES.ROOT : ROUTES.NEWS}
          className={GoBackStyles.returnHome}
        >
          <Icon icon={arrowLeftOutlined} className={GoBackStyles.arrowIcon} />
          <span>{isReturnToMainPage ? INDICATORS : GO_BACK_TO_NEWS}</span>
        </NavLink>
        <SectionTitle
          title={articleData.title}
          className={Styles.dashboardTitle}
        />
        <div className={Styles.newsWrapper}>
          <div className={Styles.newsContainer}>
            <div className={Styles.categoriesContainer}>
              {articleData.categories?.map((category) => {
                const label = newsTagsList.find((tag) => tag.id === category)
                  ?.label;

                if (!label) {
                  return null;
                }

                return <SmallTag key={category} label={label} />;
              })}
            </div>
            <div className={Styles.articleContainer}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: articleData.content }}
              />
              <Button variant="secondary" className={Styles.viewAllButton}>
                <NavLink to={ROUTES.NEWS}>{ALL_NEWS}</NavLink>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewsArticle.propTypes = {
  match: PropTypes.object,
};
