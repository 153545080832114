import React, { memo, useMemo, useCallback } from 'react';
import { Table, Icon, Spin } from 'antd';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import { useSelector } from 'react-redux';
import { calculateHeaderCellWidth } from 'helpers/salaryHelpers/rewards';
import { STATUS_LOADING } from 'const';
import {
  REWARD_SUFFIXES_BY_TYPES,
  REWARDS_GENERAL_TAB,
} from 'const/SalaryRewardsPage';
import { REWARDS_PAGE } from 'const/translations/SalaryRewardsPage';
import {
  selectEmployeeTransformRewards,
  selectFamilyRewardsTableColumns,
  selectEmployeeRewardsMeta,
  selectRewardsTableStatus,
  selectEmployeesForReward,
} from 'modules/SalaryModule/RewardsTable/selectors';
import { selectIsPendingChanges } from 'modules/SalaryModule/SalaryModal/selectors';
import { selectChosenFamilyTabId } from 'modules/SalaryModule/Rewards/selectors';
import { NoRewardsDataFound } from '../NoRewardsDataFound';
import { ColumnTitle } from './ColumnTitle';
import { EditableCell } from './EditableCell';
import Styles from './RewardsTable.module.css';

const propTypes = {
  setEmployeeRewardData: PropTypes.func,
  setEmployeeIdXlsx: PropTypes.func,

  currentPage: PropTypes.any,
  setCurrentPage: PropTypes.any,

  selectedBranch: PropTypes.any,
};

export const REWARDS_TABLE_ITEM_PER_PAGE = 10;

/**
 * @param setEmployeeIdCsv {function} - sets employee`s id whose csv file is going to be downloaded
 * */
export const RewardsTable = memo(
  ({
    setEmployeeRewardData,
    setEmployeeIdXlsx,
    currentPage,
    setCurrentPage,
    selectedBranch,
  }) => {
    const employeesRewards = useSelector(selectEmployeeTransformRewards);
    const employees = useSelector(selectEmployeesForReward);
    const employeesRewardsMeta = useSelector(selectEmployeeRewardsMeta);
    const familyByBranchColumns = useSelector(selectFamilyRewardsTableColumns);
    const isPendingChanges = useSelector(selectIsPendingChanges);

    const activeTabId = useSelector(selectChosenFamilyTabId);

    const loadingStatus = useSelector(selectRewardsTableStatus);
    const isLoading = [STATUS_LOADING].includes(loadingStatus);

    const setEmployeeIdXlsxHandler = useCallback(
      (e) => {
        const { id } = e.currentTarget.dataset;
        setEmployeeIdXlsx(id);
      },
      [setEmployeeIdXlsx]
    );

    const isTableEmpty = useMemo(
      () => employeesRewards.length === 0 || familyByBranchColumns.length === 0,
      [familyByBranchColumns, employeesRewards]
    );

    const pagination = {
      current: currentPage,
      total: selectedBranch
        ? Number(employeesRewardsMeta?.total) ||
          employeesRewards / REWARDS_TABLE_ITEM_PER_PAGE
        : employees.length,
      pageSize: REWARDS_TABLE_ITEM_PER_PAGE,
      hideOnSinglePage: true,
      onChange: (page) => setCurrentPage(page),
    };

    const columns = useMemo(
      () => [
        {
          title: REWARDS_PAGE.TABLE_NAME_COLUMN,
          width: 180,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          sorter: (a, b) => a.name.localeCompare(b.name),
          defaultSortOrder: 'ascend',
          sortDirections: ['descend', 'ascend'],
        },
        ...familyByBranchColumns
          .filter(
            ({ id }) =>
              activeTabId !== REWARDS_GENERAL_TAB.ID ||
              employeesRewards.some((employee) => Boolean(employee[id]))
          )
          .map(({ name, id, type }) => ({
            title: <ColumnTitle title={name} />,
            width: calculateHeaderCellWidth(name),
            className: Styles.editableCell,
            dataIndex: id,
            key: id,
            render: (value, record) => (
              <EditableCell
                value={value}
                rewardId={id}
                employeeId={record.id}
                handleSave={setEmployeeRewardData}
                suffix={REWARD_SUFFIXES_BY_TYPES[type]}
                disabled={isPendingChanges}
              />
            ),
          })),
        {
          title: '',
          dataIndex: 'id',
          key: 'actions',
          fixed: 'right',
          render: (id) => (
            <Icon
              onClick={setEmployeeIdXlsxHandler}
              data-id={id}
              type="download"
              className={Styles.Icon}
            />
          ),
        },
      ],
      [
        activeTabId,
        employeesRewards,
        familyByBranchColumns,
        setEmployeeIdXlsxHandler,
        setEmployeeRewardData,
        isPendingChanges,
      ]
    );

    if (isLoading) {
      return (
        <div className={Styles.loaderWrap}>
          <Spin />
        </div>
      );
    }

    if (!employeesRewards) {
      return <NoRewardsDataFound />;
    }

    return (
      <Table
        bordered
        className={clsx(
          MainStyles.table,
          MainStyles.tableCustomRow,
          Styles.table,
          {
            [Styles.overflowHidden]: isTableEmpty,
          }
        )}
        dataSource={employeesRewards}
        columns={columns}
        pagination={pagination}
        scroll={{ x: 1130 }}
        rowKey="id"
      />
    );
  }
);

RewardsTable.propTypes = propTypes;
