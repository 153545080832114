export const LAST_NEWS_TRANSLATIONS = {
  TITLE: 'Dernières actualités',
  VIEW_ALL_NEWS: 'Voir tous les actualités',
  ALL_NEWS: 'Tous les actualité',
};

export const NEWS = {
  TITLE: 'Actualités',
  CATEGORIES: 'Catégories:',
};
