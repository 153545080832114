import { request } from 'utils/request';

export const getListOfPublicationsRequest = ({ page, limit, tags }) =>
  request.get('/publications', {
    params: {
      page,
      limit,
      tags,
    },
  });

export const getListOfPublicationsTagsRequest = () =>
  request.get('/publications/hashtags');

export const getPublicationRequest = (id) => request.get(`/publications/${id}`);
