import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

import { ROUTES } from 'const';

import { SmallTag } from 'components/Tags/SmallTag';

import { ReactComponent as ArrowRight } from 'assets/icons/news-arrow-right.svg';
import newsThumbnail from 'assets/icons/news-thumbnail.svg';

import Styles from './NewsCard.module.css';

export const NewsCard = ({
  id,
  thumbnail,
  title,
  date,
  summary,
  categories,
  newsTagsList,
  toggleTag,
  currentTags,
}) => (
  <div className={Styles.newsCard}>
    <div className={Styles.newsThumbnailWrapper}>
      <img
        src={thumbnail || newsThumbnail}
        alt="thumbnail"
        className={Styles.newsThumbnail}
      />
    </div>
    <div className={Styles.newsCardContent}>
      <span className={Styles.newsDate}>
        {moment(date).format('DD MMM YYYY')}
      </span>

      <Link to={`${ROUTES.NEWS}/${id}`} className={Styles.newsTitleWrapper}>
        <span className={Styles.newsTitle}>{title}</span>
        <ArrowRight className={Styles.arrowIcon} />
      </Link>
      <span
        className={Styles.newsShortContent}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: summary }}
      />
      <div className={Styles.newsCategoriesWrapper}>
        {categories?.map((category) => {
          const isActiveTag = currentTags.includes(category);
          const label = newsTagsList.find((tag) => tag.id === category)?.label;

          if (!label) {
            return null;
          }

          if (!newsTagsList.length) {
            return (
              <div className={Styles.newsCategorySkeletonWrapper}>
                <Skeleton active paragraph={false} />
              </div>
            );
          }

          return (
            <SmallTag
              id={category}
              label={label}
              toggleTag={toggleTag}
              isActiveTag={isActiveTag}
            />
          );
        })}
      </div>
    </div>
  </div>
);

NewsCard.propTypes = {
  id: PropTypes.string.isRequired,
  thumbnail: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  categories: PropTypes.array,
  newsTagsList: PropTypes.array,
  toggleTag: PropTypes.func,
  currentTags: PropTypes.array,
};
