import { mapObjectsToItemsList } from 'helpers/common';
import { INVOICE_SEARCH_PAYMENT_STATUSES } from 'const/index';

const {
  ALL,
  DELETED,
  ACCEPTED,
  IN_PROGRESS,
  REJECTED,
  READY_FOR_PAYMENT,
  NOT_VALIDATED,
  PAID_OUTSIDE,
  PREVALIDATED,
} = INVOICE_SEARCH_PAYMENT_STATUSES;

export const LOGIC_CONDITIONS = {
  EQUAL: { label: 'Egal', value: 'equal' },
  LESS: { label: 'Inférieur', value: 'less' },
  LESS_OR_EQUAL: { label: 'Inférieur ou égal', value: 'lessOrEqual' },
  MORE: { label: 'Supérieur', value: 'more' },
  MORE_OR_EQUAL: { label: 'Supérieur ou égal', value: 'moreOrEqual' },
  BETWEEN: { label: 'Entre', value: 'between' },
};

export const INVOICE_TYPES = {
  ALL: { label: 'Tous', value: ALL },
  PURCHASE: { label: 'Achat', value: 'purchase' },
  SALE: { label: 'Vente', value: 'sale' },
};

/** Note: order of keys matters; */
const PAID_INVOICE_STATUSES_FOR_VALIDATING_USER = {
  ALL: { label: 'Tous', value: ALL },
  NOT_VALIDATED: { label: 'A traiter', value: NOT_VALIDATED },
  READY_FOR_PAYMENT: { label: 'BAP validé', value: READY_FOR_PAYMENT },
  IN_PROGRESS: { label: 'En cours', value: IN_PROGRESS },
  ACCEPTED: { label: 'Paiement effectué', value: ACCEPTED },
  PAID_OUTSIDE: { label: 'Déjà payé', value: PAID_OUTSIDE },
  PREVALIDATED: { label: 'BAP à confirmer', value: PREVALIDATED },
};

const PAID_INVOICE_STATUSES_FOR_PRE_VALIDATING_USER = {
  ...PAID_INVOICE_STATUSES_FOR_VALIDATING_USER,
  PREVALIDATED: { label: 'BAP pré-validé', value: PREVALIDATED },
};

/** Note: order of keys matters; */
export const PAID_INVOICE_STATUSES = {
  ALL: { label: 'Tous', value: ALL },
  DELETED: { label: 'Annulé', value: DELETED },
  ACCEPTED: { label: 'Paiement effectué', value: ACCEPTED },
  IN_PROGRESS: { label: 'En cours', value: IN_PROGRESS },
  PAID_OUTSIDE: { label: 'Déjà payé', value: PAID_OUTSIDE },
  NOT_VALIDATED: { label: 'Non validé', value: NOT_VALIDATED },
  READY_FOR_PAYMENT: { label: 'A payer', value: READY_FOR_PAYMENT },
  REJECTED: { label: 'Paiement initialisé', value: REJECTED },
};

export const INVOICE_SEARCH_TRANSLATIONS = {
  title: 'Rechercher une facture',
  labels: {
    keywords: 'Mot clé',
    provider: 'Client ou fournisseur',
    invoiceNumber: 'Numéro de facture',
    invoiceType: 'Type de facture',
    invoiceDateCond: 'Date',
    amountCond: 'Montant',
    status: 'Statut',
  },
  lists: {
    invoiceTypeOptions: mapObjectsToItemsList(
      Object.values(INVOICE_TYPES),
      'value',
      'label'
    ),
    searchRangeOptions: mapObjectsToItemsList(
      Object.values(LOGIC_CONDITIONS),
      'value',
      'label'
    ),
    paidStatusesForValidatingUser: mapObjectsToItemsList(
      Object.values(PAID_INVOICE_STATUSES_FOR_VALIDATING_USER),
      'value',
      'label'
    ),
    paidStatusesForPreValidatingUser: mapObjectsToItemsList(
      Object.values(PAID_INVOICE_STATUSES_FOR_PRE_VALIDATING_USER),
      'value',
      'label'
    ),
    paidStatuses: mapObjectsToItemsList(
      Object.values(PAID_INVOICE_STATUSES),
      'value',
      'label'
    ),
    invoiceDates: mapObjectsToItemsList(
      Object.values({
        DATE_DE_FACTURE: { label: 'Date de facture', value: 'invoiceDate' },
        DATE_DECHEANCE: { label: `Date d'échéance`, value: 'dueDate' },
      }),
      'value',
      'label'
    ),
    amounts: mapObjectsToItemsList(
      Object.values({
        HT: { label: `HT`, value: 'amount' },
        TTC: { label: 'TTC', value: 'amountWithVat' },
      }),
      'value',
      'label'
    ),
  },
  placeholders: {
    keywords: 'Mot clé',
    provider: 'Nom du tiers',
    invoiceNumber: 'Numéro de facture',
    invoiceDate: 'Choisir',
    dueDate: 'Choisir',
    amount: 'Choisir',
    amountWithVat: 'Choisir',
    numbers: '00.00',

    invoiceDateCond: 'Choisir',
    amountCond: 'Choisir',
  },
  BUTTONS: {
    INIT: 'Reinitialiser',
    SEARCH: 'Rechercher',
    APPROVE: 'Bon à payer',
    DISAPPROVE: 'Dévalider',
    ACCEPT: 'Paiement effectué',
    PREVALIDATE: 'Prévalider',
    RESET_VALIDATION: 'Renvoyer vers "A traiter"',
    PAY_VIA_LIBEO: 'Payer avec LIBEO',
    CANCEL: 'Fermer',
    CLOSE_MODAL: 'Fermer la fenêtre',
    DELETE: 'Supprimer la facture',
    OK: 'Ok',
    PAID_OUTSIDE: 'Déjà payé',
  },
  TABLE: {
    RESULTS_COUNTER: '{{TOTAL}} Résultat(s)',
    COLUMNS_HEADERS: {
      DATE: 'Date',
      INVOICE_NUMBER: 'Numéro',
      DUE_DATE: 'Echéance',
      PROVIDER: 'Tiers',
      INVOICE_TYPE: 'Type',
      AMOUNT: 'HT',
      VAT: 'TVA',
      AMOUNT_WITH_VAT: 'TTC',
      ACTIONS: 'Actions',
    },
    NO_RESULTS_MESSAGE:
      'Aucun document trouvé. Veuillez сhanger vos critères de recherche ci-dessus',
    TABLE_SIGNATURE: "Affichage de l'élément [X] à [Y] sur [Z] documents",
    BUTTON_LOAD_MORE: 'Plus de résultats',
    TOTAL_ROW_TITLE: 'Total des résultats',
    TOTAL_ROW_TITLE_SELECT: 'Total de la sélection',
    TOTAL_ROW_SUBTITLE: '(HT/TVA/TTC)',
    MASS_DOWNLOAD_BUTTON: 'Télécharger mes factures',
  },
  TOOLTIP: {
    PREVIEW: 'Visualiser',
    NO_PREVIEW:
      'Impossible de prévisualiser. Veuillez télécharger le document pour l’ouvrir',
    DOWNLOAD: 'Télécharger',
    APPROVE: 'Bon à payer',
    PAY: 'Payer la facture',
    PROCESS: 'Traiter la facture',
    NOT_VALIDATED: 'Non validé',
    // first for users with pay rights, second for users with validation rights
    NOT_PAYABLE_INVOICE: 'Ce type de document ne peut pas être payé',
    NOT_PAYABLE: 'Ne peut pas être payé',
    SEND_BY_EMAIL: 'Recevoir par email',
    LEAVE_A_COMMENT: 'Ajouter un commentaire',
    PAYMENT_STATUS_TOOLTIPS: {
      [ACCEPTED]: 'Paiement effectué',
      [REJECTED]: 'Paiement rejeté',
      [IN_PROGRESS]: 'Paiement en cours de traitement',
      [READY_FOR_PAYMENT]: 'Reprendre le paiement',
      [PAID_OUTSIDE]: 'Déjà payé',
    },
    VALIDATION_STATUS_TOOLTIPS: {
      NOT_VALIDATED_NORMAL: 'Facture à régler',
      NOT_VALIDATED_OVERDUE: 'Facture à régler, échéance dépassée',
      VALIDATED_NORMAL: 'Facture réglée',
      VALIDATED_OVERDUE: 'Facture réglée après échéance',
      IN_PROGRESS: 'Facture en cours',
    },
  },
  MODAL: {
    APPROVED_BY: 'Validé par',
    APPROVED_DATE: "Date d'émission du bon à payer",
    APPROVED_DUE_DATE: 'Date de paiement souhaitée',
    SELECT_DUE_DATE: 'Date de paiement',
    APPROVE_COMMENTARY: 'Commentaire',
    APPROVED_AS_GROUP_COMMENTARY: 'Facture validée en lots',
    PAID_BY: 'Payé par',
    PAID_DATE: 'Fait le',
    PAYMENT_STATUS: 'Statut paiement',
    PAYMENT_STATUS_LAST_UPDATED: 'Date mise à jour',
    DEVALIDATE_CONFIRM: 'Etes-vous sûr de vouloir dévalider cette facture ?',
    VALIDATE_AND_PAY_CONFIRM: 'Voulez-vous valider et payer cette facture ?',
    DELETE_CONFIRM: 'Etes-vous certain.e de vouloir supprimer cette facture ?',
  },
  MESSAGES: {
    EMAIL_SENT: 'E-mail envoyé avec succès',
    INVOICE_ACCEPTED: 'Facture acceptée avec succès',
    INVOICE_DELETED: 'Facture supprimée avec succès',
  },
  ERRORS: {
    CANNOT_VALIDATE_ERROR: "Vous n'êtes pas autorisé à émettre de bon à payer",
    API_KEY_MISSING: "API key n'est pas défini pour {{company}} dossier",
    INVOICE_NO_PROVIDER_NAME: 'Provider non renseigné',
    CREATE_IN_LIBEO_ERROR: 'Creation fournisseur impossible',
    CANNOT_GET_DOCUMENT:
      'Le document demandé n’a pas pu être récupéré. Le support technique a été notifié et vous informera de sa mise à disposition',
  },
};
