import React, { memo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import clsx from 'clsx';

import { ROUTES } from 'const';

import { SectionTitle } from 'components/Section/SectionTitle';
import { Button } from 'components/ui/Button';
import { NewsSmallCard } from 'components/NewsSmallCard';

import { selectLastNews, selectIsNewsLoading } from 'modules/News/selectors';
import { getListOfPublications } from 'modules/News/actions';

import { LAST_NEWS_TRANSLATIONS } from 'const/translations';

import MainStyles from 'assets/styles/main.module.css';
import StylesCard from 'components/NewsSmallCard/NewsSmallCard.module.css';

import Styles from './LastNews.module.css';

const { TITLE, VIEW_ALL_NEWS } = LAST_NEWS_TRANSLATIONS;

export const LastNews = memo(() => {
  const dispatch = useDispatch();
  const newsList = useSelector(selectLastNews);
  const isNewsLoading = useSelector(selectIsNewsLoading);

  useEffect(() => {
    dispatch(
      getListOfPublications({
        page: 1,
        limit: 2,
      })
    );
  }, [dispatch]);

  const onClickOpenAllNews = () => {};

  if (!newsList.length && !isNewsLoading) {
    return null;
  }

  return (
    <div className={clsx(Styles.dashboardHeader, MainStyles.container)}>
      <div className={Styles.titleAndViewAll}>
        <SectionTitle title={TITLE} className={Styles.dashboardTitle} />
        <Button
          variant="secondary"
          className={Styles.viewAllButton}
          onClick={onClickOpenAllNews}
        >
          <NavLink to={ROUTES.NEWS}>{VIEW_ALL_NEWS}</NavLink>
        </Button>
      </div>
      <div className={Styles.lastNewsWrapper}>
        {newsList.map(({ id, thumbnail, title, date, summary }) => (
          <NewsSmallCard
            key={id}
            id={id}
            thumbnail={thumbnail}
            title={title}
            date={date}
            summary={summary}
          />
        ))}

        {!newsList.length &&
          [1, 2].map((el) => (
            <div key={el} className={StylesCard.newsCard}>
              <Skeleton active avatar />
            </div>
          ))}
      </div>
    </div>
  );
});
