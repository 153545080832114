import React, { memo, useMemo } from 'react';
import { Table, Icon, Typography } from 'antd';
import clsx from 'clsx';
import MainStyles from 'assets/styles/main.module.css';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../../const';
import {
  COMPANY_SETTINGS,
  COMPANY_SETTINGS_REWARDS,
} from '../../../../../const/translations/SalaryCompanySettings';
import Styles from './RewardsTable.module.css';

const { Text } = Typography;

const propTypes = {
  rewardsFamilies: PropTypes.array,
  onRemoveRewardsFamily: PropTypes.func,
  managedSilae: PropTypes.bool,
};

const ITEM_PER_PAGE = 20;

const getRewardsFamilyPageRoute = (id) =>
  `${ROUTES.SALARIES_COMPANY_SETTINGS_REWARD_CREATE}/${id}`;

const getRewardFamiliesTableColumns = (onRemoveRewardsFamily, managedSilae) => [
  {
    title: COMPANY_SETTINGS_REWARDS.TITLE_COLUMN,
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    render: (title, { id }) => (
      <NavLink className={MainStyles.link} to={getRewardsFamilyPageRoute(id)}>
        {title}
      </NavLink>
    ),
  },
  {
    title: COMPANY_SETTINGS_REWARDS.ACTIONS_COLUMN,
    key: 'actions',
    width: '125px',
    fixed: 'right',
    render: (_, { id, canBeRemoved, rewardsCount }) => (
      <Text
        className={Styles.action}
        data-id={id}
        data-removable={canBeRemoved ? 'remove' : ''}
        data-count={rewardsCount}
        onClick={managedSilae ? () => {} : onRemoveRewardsFamily}
        disabled={managedSilae}
      >
        <Icon type="delete" theme="filled" className={Styles.Icon} />
        {COMPANY_SETTINGS.REMOVE_BTN_TEXT}
      </Text>
    ),
  },
];

export const RewardsTable = memo(
  ({ rewardsFamilies, onRemoveRewardsFamily, managedSilae }) => {
    const columns = useMemo(
      () => getRewardFamiliesTableColumns(onRemoveRewardsFamily, managedSilae),
      [onRemoveRewardsFamily, managedSilae]
    );

    return (
      <Table
        className={clsx(
          MainStyles.table,
          MainStyles.tableCustomRow,
          Styles.table
        )}
        dataSource={rewardsFamilies}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: ITEM_PER_PAGE, hideOnSinglePage: true }}
        scroll={{ x: 340 }}
      />
    );
  }
);

RewardsTable.propTypes = propTypes;
