import { getType, getActionCreator } from 'utils';
const prefix = '@@app/news';

export const GET_LIST_OF_PUBLICATIONS = getType(
  prefix,
  'GET_LIST_OF_PUBLICATIONS'
);
export const getListOfPublications = getActionCreator(GET_LIST_OF_PUBLICATIONS);

export const GET_LIST_OF_PUBLICATIONS_SUCCESS = getType(
  prefix,
  'GET_LIST_OF_PUBLICATIONS_SUCCESS'
);
export const getListOfPublicationsSuccess = getActionCreator(
  GET_LIST_OF_PUBLICATIONS_SUCCESS
);

export const GET_LIST_OF_PUBLICATIONS_TAGS = getType(
  prefix,
  'GET_LIST_OF_PUBLICATIONS_TAGS'
);
export const getListOfPublicationsTags = getActionCreator(
  GET_LIST_OF_PUBLICATIONS_TAGS
);

export const GET_LIST_OF_PUBLICATIONS_TAGS_SUCCESS = getType(
  prefix,
  'GET_LIST_OF_PUBLICATIONS_TAGS_SUCCESS'
);
export const getListOfPublicationsTagsSuccess = getActionCreator(
  GET_LIST_OF_PUBLICATIONS_TAGS_SUCCESS
);

export const GET_PUBLICATION = getType(prefix, 'GET_PUBLICATION');
export const getPublication = getActionCreator(GET_PUBLICATION);

export const GET_PUBLICATION_SUCCESS = getType(
  prefix,
  'GET_PUBLICATION_SUCCESS'
);
export const getPublicationSuccess = getActionCreator(GET_PUBLICATION_SUCCESS);
