/* eslint-disable no-useless-escape */
import { FORBIDDEN_MIME_TYPES, MIME_TYPES } from 'const/mimeTypes';
import { EXECUTABLE_FILES_EXTENSIONS } from 'const/extensions';
import { history } from 'routing/history';

/**
 * redirects to given url with window.location
 * @param to
 */
export const redirect = (to) => {
  window.location = to;
};

/**
 * Maps array of objects to use specified
 * props of these objects as value and label of
 * components list.
 * @param {array<Object>} items
 * @param {String} valueKey
 * @param {String} labelKey
 * @returns array of {value, label}
 */
export const mapObjectsToItemsList = (items, valueKey, labelKey, keyVal) =>
  items.map((item) => {
    const value = item[valueKey];
    const label = item[labelKey] || value;
    const key = item[keyVal] || value;

    return { value, label, key };
  });

/**
 * Maps array of string values to use in list
 * Each value is used as key and must be unique
 * @param valuesArray
 * @returns {{label: *, value: *, key: *}[]}
 */
export const mapValuesToItemsList = (valuesArray = []) =>
  valuesArray.map((value) => ({ key: value, value, label: value }));

/**
 * Omit empty keys
 * @param {Object} object
 * @param {Array} keys  - Omit only specified keys or all
 * @returns {Object}
 */
export const omitEmptyKeys = (object, keys = []) =>
  Object.entries(object).reduce(
    (acc, [key, value]) =>
      (keys.length && !keys.includes(key)) || value || value === false
        ? {
            ...acc,
            [key]: value,
          }
        : acc,
    {}
  );

/**
 * Formik refuse to play nice with ant-d Select
 * this is a workaround
 * Don't forget to use useCallback when implementing it
 * @param field
 * @param setFieldValue
 * @returns {function(*=): *}
 */
export const updateField = (field, setFieldValue) => (value) =>
  setFieldValue(field, value);

export const convertBytesToMegabytes = (bytesAmount, precision = 1) =>
  (bytesAmount / (1024 * 1024)).toFixed(precision);

export const convertBytesToKilobytes = (bytesAmount, precision = 1) =>
  (bytesAmount / 1024).toFixed(precision);

export const getExtensionFromName = (fileName) => {
  if (!fileName) {
    return MIME_TYPES.txt;
  }

  const extension = fileName.split('.').reverse()[0];
  return MIME_TYPES[extension] || MIME_TYPES.txt;
};

/**
 * Provides file with mime-type to let browser know how to handle this file,
 * then downloads it.
 * @param {Blob} fileData - The data received from backend.
 * To allow receiving blobs, add { responseType: 'blob' } as config to your axios request.
 * In other cases, use `new Blob([response.data], options)`
 * @param {string} fileName - The desired file name of downloading file.
 * If { responseType: 'blob' } is set, the filename gets remapped with the fileData:
 * See src/utils/requestHelpers/responseMapper.
 */
export const downloadFile = (fileData, fileName = 'file') => {
  // Splitting filename to get extension, so we can correctly set MIME type
  // (e.g. for iPhone) to be able to open file in an app, that supports it

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(fileData);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  setTimeout(() => window.URL.revokeObjectURL(link.href), 10000);
};

/**
 * Opens file in a new browser tab.
 * @param {Blob} fileData - The data received from backend.
 * To allow receiving blobs, add { responseType: 'blob' } as config to your axios request.
 * In other cases, use `new Blob([response.data], options)`
 * See src/utils/requestHelpers/responseMapper.
 */
export const openFile = (fileData) =>
  window.open(window.URL.createObjectURL(fileData), '_blank');

/**
 * Opens provided URL in a new browser tab
 */
export const openURLInNewTab = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Helper checks if file mime type or extension matches
 * the list of forbidden mime types and extensions
 */
export const isFileForbidden = (file) => {
  const nameChunks = file.name.split('.');
  const extension = nameChunks[nameChunks.length - 1].toLowerCase();
  return (
    FORBIDDEN_MIME_TYPES.includes(file.type) ||
    EXECUTABLE_FILES_EXTENSIONS.includes(extension)
  );
};

/**
 * Takes debtor or creditor page data and
 * trims strings and replaces null values with empty strings to
 * allow reliable sorting
 */
export const trimDetailedInfoTableData = (data) =>
  data.map((item) => ({
    ...item,
    operationNumber: item.operationNumber ? item.operationNumber.trim() : '',
    accountName: item.accountName ? item.accountName.trim() : '',
    description: item.description ? item.description.trim() : '',
  }));

export const calculateDetailedInfoPageTotals = (dataSource = []) =>
  dataSource.reduce(
    (acc, { debit, credit, amount }) => {
      acc.debit += debit;
      acc.credit += credit;
      acc.amount += amount;
      return acc;
    },
    { debit: 0, credit: 0, amount: 0 }
  );

/**
 * Separate number by thousands
 */
export const separateNumber = (num) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

/**
 * Comparator for an array of objects with "label" property
 */
export const compareByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }

  if (a.label > b.label) {
    return 1;
  }

  return 0;
};

/**
 *
 * @param {string} token JWT token
 * /**
 * @typedef {Object} ParsedJWT
 * @see https://jwt.io/introduction
 * @see https://www.rfc-editor.org/rfc/rfc7519#section-4.1
 *
 * @returns {ParsedJWT} Token's payload
 */
export const parseJwt = (token) => {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
  } catch (e) {
    return null;
  }
};

// Redirects user to the given url inside the app.
export const handleInternalRedirect = (url) => {
  history.push(url);
  window.scrollTo(0, 0);
};

export const isFileNameIncorrect = (fileName) => {
  const nameWithoutExtension = fileName
    .split('.')
    .slice(0, -1)
    .join('.');

  const regex = /^[^\\/:\*\?"<>\|]+$/;
  const regex2 = /^\./;

  return !regex.test(nameWithoutExtension) || regex2.test(nameWithoutExtension);
};

export const isFileNameRepeat = (file, files) => {
  const isRepeat = files.filter(
    (el) => el.name === file.name && el.uid !== file.uid
  ).length;

  return isRepeat;
};

export const isFileRequired = (fileName) => {
  const nameWithoutExtention = fileName
    .split('.')
    .slice(0, -1)
    .join('.');

  return !nameWithoutExtention;
};
