import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import clsx from 'clsx';
import { Pagination, Skeleton } from 'antd';
import { Icon } from '@iconify/react';

import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';

import { ROUTES } from 'const';
import { NAVIGATION_LABELS, NEWS } from 'const/translations';

import {
  getListOfPublicationsTags,
  getListOfPublications,
} from 'modules/News/actions';
import {
  selectNewsTags,
  selectNews,
  selectIsNewsLoading,
} from 'modules/News/selectors';

import { SectionTitle } from 'components/Section/SectionTitle';
import { NewsCard } from 'components/NewsCard';
import { CategoryTag } from 'components/Tags/CategoryTag';
import GoBackStyles from 'components/SalaryComponents/SalaryBreadcrumbs/SalaryBreadcrumbs.module.css';
import MainStyles from 'assets/styles/main.module.css';

import { useScrollToTop } from 'hooks/useScrollToTop';

import StylesCard from 'components/NewsCard/NewsCard.module.css';

import Styles from './News.module.css';

const { TITLE, CATEGORIES } = NEWS;

const newsLimit = 10;

export const News = () => {
  const dispatch = useDispatch();
  const newsTagsList = useSelector(selectNewsTags);
  const isNewsLoading = useSelector(selectIsNewsLoading);
  const { data, meta } = useSelector(selectNews);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentTags, setCurrentTags] = useState([]);

  const toggleTag = useCallback(
    (id) => {
      const newTags = currentTags.includes(id)
        ? currentTags.filter((tag) => tag !== id)
        : [...currentTags, id];
      setCurrentTags(newTags);
      setCurrentPage(1);
    },
    [currentTags]
  );

  useEffect(() => {
    dispatch(getListOfPublicationsTags());
    dispatch(
      getListOfPublications({
        page: currentPage,
        limit: newsLimit,
        tags: currentTags.join(',') || undefined,
      })
    );
  }, [currentPage, currentTags, dispatch]);

  const usedHashtags = useMemo(
    () =>
      data?.reduce(
        (acc, article) =>
          article.categories
            ? [...new Set([...acc, ...article.categories])]
            : acc,
        []
      ),
    [data]
  );

  useScrollToTop();

  if (data && !data.length && !isNewsLoading) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  return (
    <div className={Styles.background}>
      <div className={clsx(Styles.dashboardHeader, MainStyles.container)}>
        <NavLink to={ROUTES.ROOT} className={GoBackStyles.returnHome}>
          <Icon icon={arrowLeftOutlined} className={GoBackStyles.arrowIcon} />
          <span>{NAVIGATION_LABELS.INDICATORS}</span>
        </NavLink>
        <SectionTitle title={TITLE} className={Styles.dashboardTitle} />
        <div className={Styles.newsWrapper}>
          <div className={Styles.newsContainer}>
            {!isNewsLoading &&
              data?.map(
                ({ id, thumbnail, title, date, summary, categories }) => (
                  <NewsCard
                    key={id}
                    id={id}
                    thumbnail={thumbnail}
                    title={title}
                    date={date}
                    summary={summary}
                    categories={categories}
                    newsTagsList={newsTagsList}
                    toggleTag={toggleTag}
                    currentTags={currentTags}
                  />
                )
              )}
            {isNewsLoading &&
              Array(newsLimit)
                .fill(0)
                .map((_, i) => i)
                .map((el) => (
                  <div key={el} className={StylesCard.newsCard}>
                    <Skeleton active avatar />
                  </div>
                ))}

            <div className={Styles.paginationWrapper}>
              {meta?.total > newsLimit && (
                <Pagination
                  current={currentPage}
                  total={meta?.total}
                  pageSize={newsLimit}
                  onChange={setCurrentPage}
                />
              )}
            </div>
          </div>
          <div className={Styles.categoriesContainer}>
            {Boolean(usedHashtags?.length) && (
              <div className={Styles.categoriesTitle}>{CATEGORIES}</div>
            )}
            {newsTagsList.map(({ id, label }) => {
              const isActiveTag = currentTags.includes(id);
              const isUsedHashtag = usedHashtags?.includes(id);

              if (!isUsedHashtag) {
                return null;
              }

              return (
                <CategoryTag
                  id={id}
                  label={label}
                  toggleTag={toggleTag}
                  isActiveTag={isActiveTag}
                />
              );
            })}
            {!newsTagsList.length &&
              [1, 2, 3].map((el) => (
                <Skeleton key={el} active paragraph={false} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
