import { fromJS } from 'immutable';
import {
  GET_LIST_OF_PUBLICATIONS,
  GET_LIST_OF_PUBLICATIONS_SUCCESS,
  GET_LIST_OF_PUBLICATIONS_TAGS_SUCCESS,
  GET_PUBLICATION_SUCCESS,
} from '../actions';

const initialState = fromJS({
  news: {},
  isNewsLoading: false,
  hashtags: {},
  publication: {},
});

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_OF_PUBLICATIONS:
      return state.set('isNewsLoading', true);
    case GET_LIST_OF_PUBLICATIONS_SUCCESS:
      return state
        .set('news', fromJS(action.payload))
        .set('isNewsLoading', false);
    case GET_LIST_OF_PUBLICATIONS_TAGS_SUCCESS:
      return state.set('hashtags', fromJS(action.payload));
    case GET_PUBLICATION_SUCCESS:
      return state.set('publication', fromJS(action.payload));
    default:
      return state;
  }
};
