import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { formatByOrdersOfMagnitude } from '../../../../modules/SalaryModule/RewardsTable/utils';
import Styles from './RewardsTable.module.css';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export const TextCell = ({ value, suffix, onClick, disabled }) => (
  <div
    className={cn(Styles.customCell, { [Styles.customCellDisabled]: disabled })}
    onClick={!disabled && onClick}
  >
    <Text>{formatByOrdersOfMagnitude(value.toString())}</Text>
    <Text className={Styles.customCellSuffix}>{suffix}</Text>
  </div>
);

TextCell.propTypes = propTypes;
