export const HOMEPAGE_FORM_TRANSLATIONS = {
  TITLE: 'Bienvenue sur Exco ! Découvrez les services disponibles',
  FORMS: {
    SUBTITLE: 'Mes formulaires',
    LABEL_ADDING_EMPLOYEE: 'Ajouter un salarié',
    TEXT_ADDING_EMPLOYEE:
      'Un nouvel employé est arrivé ? Utilisez ce formulaire afin que notre équipe dédiée à la gestion de la paie sur votre dossier puisse en intégrer les informations. Vous pourrez ici renseigner son état civil, son poste, salaire, et nous communiquer les pièces justificatives nécessaires. Si vous n’avez pas toutes les informations à cet instant, vous pourrez les compléter plus tard.',
  },
  IMG_ALT_TEXTS: {
    RECHERCHER_ALT: 'Rechercher une facture image du module',
    ADD_EMPLOYEE_ALT: 'Ajouter un salarié image du module',
    UPLOAD_DOCUMENTS_ALT: 'Envoyer des documents image du module',
    CABINET_ALT: 'Rechercher une facture image du module',
  },
  DATA: {
    SUBTITLE: 'Mes données',
    LABEL_FIND_INVOICE: 'Recherche de factures',
    TEXT_DOCUMENT:
      'Vous trouverez ici les documents que EXCO a mis à votre disposition. Ils sont rangés par catégorie, vous pourrez visualiser et télécharger les documents que vous souhaitez',
    LABEL_DOCUMENT: 'Dossier partagé avec Exco',
    TEXT_FIND_INVOICE:
      'Vous recherchez des factures d’achat ou de vente comptabilisées ? Vous pourrez ici les retrouver en utilisant une recherche plein texte sur l’ensemble du corps de la facture ou bien par critères (fournisseur, client, type de document, montant, date...). Vous pourrez ainsi les consulter ou les télécharger ',
    TEXT_2_DOCUMENT:
      "Instead, we'll show you how to access Auto Layout, what are the properties involved, and give you some tips. Note: If you want to copy Prototype connections from the page, you need to have the Prototype Tab open. If you copy from the Design Tab, we won't copy any Prototype Connections.",
    TEXT_3_DOCUMENT:
      "Auto Layout is a powerful feature with many moving parts. If we were to write a traditional step-by-step process, we'd end up in a Choose Your Own Adventure game!",
    LABEL_SENDING_DOCUMENT: 'Envoi de documents',
    TEXT_SENDING_DOCUMENT:
      'Vous avez des documents à nous transmettre ? Vous pourrez ici, par glisser/déposer ou par la fonction parcourir nous les communiquer pour traitement. Une fois traités, ils seront disponibles pour la recherche',
  },
  LIST_SENDING_DOCUMENT: {
    UNIT_1: 'Create buttons that grow or shrink as you edit the text label',
    UNIT_2: 'Quickly build lists that adapt to new items',
    UNIT_3: 'Combine Auto Layout Frames to build complete interfaces',
    UNIT_4: 'Add Auto Layout to existing Components',
  },
  SHOW_MORE: 'Afficher plus',
  ADVANTAGES_SECTION_TITLE:
    'Simplifier le pilotage de votre entreprise au quotidien',
  ADVANTAGES_TITLES: {
    SIMPLE: 'Simple',
    ACCESSIBLE: 'Accessible',
    EFFECTIVE: 'Efficace',
    RELIABLE: 'Fiable',
  },
  ADVANTAGES_TEXT: {
    SIMPLE_ADVANTAGE: "Un point d'accès unique à tous vos outils et services.",
    ACCESSIBLE_ADVANTAGE:
      'Disponibilité 24/7 sur ordi, tablette ou smartphone.',
    EFFECTIVE_ADVANTAGE: 'Tout pour gagner en visibilité et en trésorerie.',
    RELIABLE_ADVANTAGE:
      'Adieu les classeurs, les fiches navettes et appels dans tous les sens.',
  },
  SERVICES_SECTION_TITLE: 'Mes services',
  CARDS_TITLES: {
    MY_SALES: 'Mes ventes - FACT',
    MY_EXPENSES: 'Mes dépenses - PAY',
    MY_DOCUMENTS: 'Mes documents',
    MY_SALARIES: 'Mes ressources humaines',
    HELP: 'Aide',
    MY_EXCO: 'Bouquet My Exco',
    UPLOAD_DOCUMENTS: 'Envoi de documents',
    DOCUMENTS_CABINET: 'Dossier partagé',
    SEARCH_INVOICES: 'Recherche de factures multicritère',
    INDICATORS: 'Indicateurs de gestion et RH',
    TREASURY: 'Suivi de trésorerie',
    WAIBI: 'Mon tableau de bord',
  },
  CARDS_FUNCTIONAL: {
    MY_SALES_1:
      'Etablissez, éditez vos devis et factures 100% conformes rapidement',
    MY_SALES_2: 'Gérez vos règlements et vos relances facilement',
    MY_SALES_3:
      'Pilotez votre chiffre d’affaires et gérez votre trésorerie en un coup d’oeil',
    MY_EXPENSES_1: 'Gérez et réglez vos factures fournisseurs simplement',
    MY_EXPENSES_2:
      "Payez sans effort et sans limite : pas de document XML, ni d'IBAN",
    MY_EXPENSES_3: 'Gardez le contrôle sur vos dépenses et votre trésorerie',
    MY_DOCUMENTS_1: 'Déposez vos factures en toute simplicité',
    MY_DOCUMENTS_2:
      'Trouvez vos factures en quelques clics grâce à la recherche multicritères',
    MY_DOCUMENTS_3:
      'Retrouvez vos documents dématérialisés, où que vous soyez, à n’importe quel moment',
    HELP_1:
      'Prenez rdv avec un coach digital pour vous aider à la prise en main de MyExco',
    HELP_2: 'Retrouvez les FAQ pour les questions les plus fréquentes',
    HELP_3: 'Visionnez des vidéo tutos pour voir toutes les manipulations',
    UPLOAD_DOCUMENTS_1: 'Déposez vos factures numériques en toute simplicité',
    UPLOAD_DOCUMENTS_2:
      "Une facture ou un ticket papier ? en 1 photo c'est envoyé",
    UPLOAD_DOCUMENTS_3: "Accédez à l'historique des factures envoyées",
    DOCUMENTS_CABINET_1:
      'Accédez à vos documents dématérialisés et classés par thème',
    DOCUMENTS_CABINET_2:
      'Disponible 24h/24 et 7j/7, de votre ordinateur et smartphone',
    DOCUMENTS_CABINET_3:
      'Visualisez, téléchargez ou transférez-les selon vos besoins',
    MY_SALARIES_1:
      'Transmettez simplement et de façon sécurisée les données de paie de vos salariés',
    MY_SALARIES_2:
      'Retrouvez toutes les informations administratives de vos salariés',
    MY_SALARIES_3:
      'Un nouveau salarié arrive ? complétez sa fiche en conformité avec la législation en vigueur',
    INDICATORS_1: 'Chiffre d’affaires réalisé et potentiel',
    INDICATORS_2: 'Coût salarial et heures travaillées',
    INDICATORS_3: 'Chiffre généré par heure travaillée',
    INDICATORS_4: '% Charges de personnel / Chiffre d’affaires',
    TREASURY_1: 'Trésorerie disponible',
    TREASURY_2: 'Сe que vous devez (dettes fournisseurs)',
    TREASURY_3: "Сe que l'on vous doit (créances clients)",
    SEARCH_INVOICES_1:
      'Suivez et analysez vos factures de ventes et d’achats grâce à la recherche multicritère',
    SEARCH_INVOICES_2: 'Comparez et pointez les prix d’achat',
    SEARCH_INVOICES_3: 'Suivez les volumes d’achat',
    WAIBI_1: `Disposez d'indicateurs de gestion`,
    WAIBI_2: `Bénéficiez d'un accompagnement personnalisé`,
    WAIBI_3: `Pilotez votre entreprise de manière plus sereine et réactive`,
  },
  BUTTONS_TEXT: {
    SUBSCRIBE: 'Souscrire',
    LEARN_MORE: 'En savoir plus',
    GET_ACCESS: 'Accéder',
    ADD_TO_CART: 'Ajouter au panier',
    GO_TO_CART_PAGE: 'Voir mon panier',
    ACCESS_STORE: 'Accéder au Store',
    CONTACT_WAIBI_CONSULTANT: 'Etre contacté par mon conseiller',
  },
  YEAR_MONTH_SWITCH: {
    YEAR: 'Annuels',
    MONTH: 'Mensuels',
  },
  CHARTS_VIEW_SWITCH: {
    VIEW: 'Vue',
  },
  SHORT_PREVIEW_CARDS_DESCRIPTIONS: {
    BALANCE: 'Trésorerie disponible au {{DATE}}',
    DEBTOR: 'Vos clients vous doivent',
    CREDITOR: 'Vous devez à vos fournisseurs',
  },
  RELIABILITY_INDEX_CARD: {
    DISABLED_INDEX:
      'Données indicatives sur la base des informations communiquées par vos soins',
    DESCRIPTION: 'Indice de fiabilité ',
    high: {
      TITLE: 'Elevé',
      POPUP:
        'Nous avons pu déterminer vos indicateurs de gestion de manière fiable sur la base des informations communiquées',
    },
    medium: {
      TITLE: 'Moyen',
      POPUP:
        'Certaines informations et pièces nous manquent pour vous assurer une meilleure et plus fiable lisibilité de votre activité ',
    },
    low: {
      TITLE: 'Faible',
      POPUP:
        'Nous n’avons pas pu déterminer vos indicateurs de gestion de manière fiable sur la base des informations communiquées',
    },
    'N/A': {
      TITLE: 'Non renseigné',
      POPUP: 'N/A POPUP TEXT',
    },
  },
  MY_INDICATORS_SECTION_TITLE: 'Mes indicateurs',
  FISCAL_YEAR_ACCUMULATION: 'Cumul à fin {{LAST_UPDATE_MONTH}}',
  FISCAL_MONTH_ACCUMULATION: 'En cumul {{MONTH}}',
  ERROR_DEACTIVATED_COMPANY: `Votre dossier client a été désactivé sur votre demande ou dans le cadre d'une maintenance. Pour toute information complémentaire, vous pouvez vous adresser à nos services techniques via le menu « Aide »`,
  MY_USEFUL_LINKS: 'Mes liens utiles',
  ADD_LINK: 'Ajouter un lien',
  EDIT_LINK: 'Modification du lien',
  DELETE_LINK: 'Suppression du lien',
  NEW_LINK: 'Nouveau lien',
  LINK_NAME: 'Nom du lien',
  LINK_ADDRESS: 'Adresse du lien',
};
