import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT_WITHOUT_SECONDS } from 'const';

export const WEEK_DAYS = {
  MONDAY: 'Lundi',
  TUESDAY: 'Mardi',
  WEDNESDAY: 'Merсredi',
  THURSDAY: 'Jeudi',
  FRIDAY: 'Vendredi',
  SATURDAY: 'Samedi',
  SUNDAY: 'Dimanche',
};

export const NAVIGATION_LABELS = {
  FORMS: 'Mes formulaires',
  DATA: 'Mes données',
  INFORMATION: 'Mes informations',
  SERVICES: 'Mes services',
  INDICATORS: 'Mes indicateurs',
  MES_VENTES: 'Mes ventes',
  CONTACTS: 'Mes contacts EXCO',
  NEW_EMPLOYEE_REGISTER: "Enregistrement d'un salarié",
  UPLOAD_DOCUMENTS: 'Envoi de documents',
  INVOICE_SEARCH: 'Rechercher une facture',
  DOCUMENTS_CABINET: 'Consulter mes documents',
  DISCONNECT: 'Déconnexion',
  ABSENCE_MANAGEMENT_REDIRECT: 'Gestion des absences',
  COLLABORATIVE_ACCOUNTING_REDIRECT: 'ISAcompta Collaboratif',
  ADDITIONAL_USERS: 'Gestion des utilisateurs',
};

export const SIDEBAR_LABELS = {
  HOME: 'Accueil',
  MY_SALES: 'Mes ventes',
  MY_EXPENSES: 'Mes dépenses',
  MY_DOCUMENTS: 'Mes documents',
  UPLOAD_DOCUMENTS: 'Envoi de documents',
  INVOICE_SEARCH: 'Recherche de facture',
  DOCUMENTS_CABINET: 'Dossier partagé avec Exco',
  HELP: 'Aide',
  SALARIES: 'Mes ressources humaines',
  STORE: 'Store',
  WAIBI: 'Mon tableau de bord',
};

export const SALARY_HEADER_LABELS = {
  MAIN: 'Mes Ressources Humaines',
  EMPLOYEES: 'Mes salariés',
  EXCO_SNIFI: 'Entrée d’un salarié',
  REWARDS: 'Eléments variables de paie',
  CALENDAR: 'Calendrier',
  COMPANY_SETTINGS: 'Paramètres entreprise',
  SEND_INFO: 'Transmettre les données de paie',
  CONTACT_HR: 'Contact & Envoi de documents RH',
  CONTACT_HR_SHORT: 'Contact RH',
};

export const getSilaeRefreshEmployeesTranslation = (timezoneDate) => {
  const date = moment(timezoneDate).format(DATE_FORMAT);
  const time = moment(timezoneDate).format(TIME_FORMAT_WITHOUT_SECONDS);
  return `Mise à jour du ${date} à ${time}`;
};

export const SALARY_HOMEPAGE_DESCRIPTIONS = {
  EMPLOYEES:
    'Retrouvez ici toutes les informations administratives de vos salariés.',
  EXCO_SNIFI: 'Un nouveau salarié arrive ? Complétez sa fiche ici.',
  REWARDS:
    'Saisissez ici les données mensuelles pour établir les bulletins (primes, acomptes, etc.).',
  CALENDAR:
    'Simplifiez-vous le suivi des absences et organisez le planning des congés de vos salariés.',
  COMPANY_SETTINGS:
    'Consultez les informations générales de l’entreprise et paramétrez les horaires de travail applicables à vos salariés.',
  CONTACT_HR:
    'Simplifiez vos échanges avec Exco et faites-nous parvenir vos documents en toute sécurité.',
};

export const API_KEY_MODAL_TRANSLATIONS = {
  TITLE: '{{name}} api key',
  BUTTON: 'Fixer',
  HEADER_BUTTON: 'API key',
  ERROR_EMPTY: 'API key ne peut pas être vide',
  ERROR_NOT_VALID: "Api_key n'est pas valide",
};

export const COMMON_LABELS = {
  OPTIONAL: '(optionnel)',
  OTHER: 'Autre',
  CHOOSE: 'Choisir',
  DATE_FORMAT: 'jj/mm/aaaa',
  LOADING: 'Chargement...',
  MB_SHORT: 'Mo',
  KB_SHORT: 'Ko',
  YES: 'Oui',
  NO: 'Non',
  LEARN_MORE: 'En savoir plus',
  ACCESS: 'Accéder',
  SEND: 'Envoyer',
};

export const GENERAL_ERRORS = {
  ACCOUNT_LOCKED:
    "Votre compte a été bloqué en raison d'un trop grand nombre d'échecs de connexion. Merci de contacter votre responsable de dossier",
  SSO_DISABLED: 'Pas de code dossier facturation renseigné',
  SSO_TOKEN_NOT_FOUND: 'Code dossier facturation incorrect',
};

export const EXPANDABLE_PARAGRAPH_TRANSLATIONS = {
  EXPAND: 'Suite',
  COLLAPSE: 'Réduire',
};

export const COMMON_TEXT_TRANSLATIONS = {
  LAST_UPDATE: 'Mise à jour selon les informations communiquées le : {{DATE}}',
  CUMULATIVE: 'En cumul',
};

export const NOTIFICATION_TEXT_TRANSLATIONS = {
  MOBILE_DISCLAIMER: `Nous vous recommandons d'utiliser un ordinateur ou une tablette pour une navigation optimale sur My Exco`,
};

export {
  INVOICE_SEARCH_TRANSLATIONS,
  LOGIC_CONDITIONS,
  INVOICE_TYPES,
} from './InvoiceSearch';

export {
  ADD_EMPLOYEE_TITLE_TRANSLATIONS,
  ADD_EMPLOYEE_FORM_TRANSLATIONS,
} from './AddEmployee';

export { LOGIN_PAGE_TRANSLATIONS } from './LoginPage';

export { CHANGE_PASSWORD_TRANSLATIONS } from './ChangePassword';

export { FORGOTTEN_PASSWORD_TRANSLATIONS } from './ForgottenPassword';

export { HOMEPAGE_FORM_TRANSLATIONS } from './HomePage';

export { UPLOAD_DOCUMENT_TRANSLATIONS } from './UploadDocuments';

export { DOCUMENTS_CABINET_TRANSLATIONS } from './DocumentsCabinet';

export { ACCESS_DENIED_PAGE_TRANSLATIONS } from './AccessDenied';

export { CONTACTS_PAGE_TRANSLATIONS } from './Contacts';

export {
  CREDITORS_TRANSLATIONS,
  CREDITOR_TRANSLATIONS,
  DEBTORS_TRANSLATIONS,
  DEBTOR_TRANSLATIONS,
  COUNTERPARTY_TABLE_TRANSLATIONS,
} from './Counterparties';

export { NPS_MODAL_TRANSLATIONS } from './NetPromoterScoreModal';

export { MASS_INVOICE_ACTIONS_TRANSLATIONS } from './MassInvoiceActionsModal';

export { INVOICE_SEARCH_COMMENTARY_MODAL } from './InvoiceSearchCommentaryModal';

export { MY_INFORMATION_PAGE_TRANSLATIONS } from './MyInformation';
export { LAST_NEWS_TRANSLATIONS, NEWS } from './News';
